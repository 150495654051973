$season-toggle-break: $bp-lrg;
.season-toggle{
    position: relative;
    
}
.season-toggle__wrapper{
    @include gutter1();
    @media all and (max-width: $season-toggle-break) {
        padding-left: 0;
    }
}
.season-toggle__container{
    position: relative;
}
.season-toggle__item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media all and (min-width: $season-toggle-break) {
        justify-content: flex-end;
    }
    .breadcrumbs + .season-toggle &{
        
        @media all and (min-width: $season-toggle-break) {
            position: absolute;
            bottom: -100%;
            right: 0;
        }
    }
}
.season-toggle__list-item-link{
    color: $menu-top-link;
    display: block;
    padding: 18px 24px;
    font-size: 1.25rem;
    font-family: $font-alert;
    transition: all .35s;
    &:hover,
    &.-item-active{
        text-decoration: none;
        color: $brand-primary;
    }
    .-toggle &{
        font-size: .875rem;
        padding-right: 54px;
        margin-right: -30px;
        z-index: 1;
        color: $menu-seasontoggle-link;
        //opacity: .5;
        &.-active{
            opacity: 1;
        }
    }
    .season-toggle__switch + &{
        padding-right: 24px;
        margin-right: 0;
        padding-left: 54px;
        margin-left: -30px;
    }
}
.season-toggle__switch{
    display: block;
    background-color: $menu-top-link;
    position: relative;
    width: 60px;
    height: 32px;
    border: 1px solid $menu-top-link;
    border-radius: 30px;
}
.season-toggle__switch-indicator{
    display: block;
    width: 28px;
    height: 28px;
    position: absolute;
    border-radius: 30px;
    background-color: $white;
    transition: all .35s;
    left: 50%;
    top: 1px;
    .-winter &{
        left: 1%;
    }
}

// STYLES TO HIDE AND SHOW CONTENT SEASONALLY
body{
    &.-winter-season{
        *.-summer-only{
            display: none;
        }
        *.-winter-only{
            transition: opacity .35s;
            opacity: 1;
        }
    }
    &.-winter-transition{
        *.-summer-only{
            transition: opacity .35s;
            opacity: 0;
        }
        *.-winter-only{
            transition: opacity .35s;
            opacity: 0;
        }
    }
    &.-summer-season{
        *.-winter-only{
            display: none;
        }
    }
    &.-summer-transition{
        *.-winter-only{
            transition: opacity .35s;
            opacity: 0;
        }
    }
}
.season-toggle__info{
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $toggle-tooltip-bg;
    overflow: hidden;
    opacity: 0;
    height: 0;
    transition: opacity .35s ease-out;
    border-radius: 30px;
    width: 300px;
    z-index: 1;
    &.-active{
        opacity: 1;
        height: auto;
    }
}
.season-toggle__info-wrapper{
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.season-toggle__copy{
    font-size: 10px;
    padding-left: 15px;
    color: $toggle-tooltip-text;
}
.season-toggle__info-btn{
    svg{
        path{
            opacity: $toggle-tooltip-opacity;
            fill: $brand-primary;
        }
    }
}
.season-toggle__btn{
    cursor: pointer;
    background-color: transparent;
    border: none;
    svg rect{
        fill: $toggle-tooltip-text;
    }
}