&-trails {
    padding: 0 0 rem($comp-pad);

    @media all and (min-width: $bp-med) {
        padding: 0 0 rem($comp-pad * 3);
    }

    &__wrap {
        @include gutter1;

        .mc-trails > & {

            > *:not(.mc__heading-content) {
                @include display(inline-block);
                vertical-align: middle;

                &:first-child:not(.mc__callout-keys) {
                    margin-right: .5em;
                }
            }
        }
    }

    &__graph {
        flex: 1 0 25%;
        @include position;
        //max-width: rem(400px);
        padding: rem($comp-pad) 0;

        @media all and (min-width: $bp-sml) {
            padding: rem($comp-pad);
        }

        &s {
            @include display(flex, row wrap, space-around);
            margin: rem(calc($comp-pad * 2)) rem(calc($comp-pad * -1));
        }

        &-value {
            @include display(block);
            @include font(rem(50px), null, $font-h2);

            @media all and (min-width: $bp-med) {
                font-size: rem(65px);
            }

            @media all and (min-width: $bp-xlrg) {
                font-size: rem(85px);
            }
            
            &s {
                @include display(flex, row nowrap, center, center);
                @include position(absolute, 1, (top, left, right, bottom), (0, 0, 0, 0));
                width: 100%;
                pointer-events: none;
                font-family: $font-h2;

                &-p {
                    text-align: center;
                    @include font(rem(20px), 1, $font-paragraph);
                    color: $white;
                }
            }
        }
    }

    &__table {

        svg:not(:only-child) {
            margin-right: 0.5em;

            @media all and (min-width: $bp-sml) {
                margin-right: 1em;
            }
        }
    }

    h2 {
        margin: 0;
    }

    p {
        margin-top: rem($comp-pad);
        margin-bottom: rem($comp-pad);
    }

    .mc__ctrl-tab-btn {
        color: $mc-trails__btn-color;

        @include hover {
            background-color: $mc-trails__btn-color;
            color: $white;
        }

        &.-active {
            background-color: $mc-trails__btn-color;
            color: $white;
        }
    }

    .mc__btn {
        border-color: $mc-trails__btn-color;
        color: $mc-trails__btn-color;

        @include hover {
            background-color: $mc-trails__btn-color;

            color: $white;
        }

        &.-active {
            background-color: $mc-trails__btn-color;
            color: $white;
        }

        &s {
            margin-top: rem(calc($comp-pad * 2));
        }
    }

    .accordion__wrapper {
        padding: 0;
    }

    @media all and (max-width: $bp-med) {

        + .mc-forecast,
        + .mc-uphill {
            padding-top: 0;
        }
    } 
}