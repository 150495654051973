$mountain-report-dropdown-break : $bp-lrg;
.mountain-report-dropdown{
    background-color: $brand-primary;
    height: 0vh;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity .35s;
    z-index: -1;
    .-main-nav-open &{
        //z-index: -1;
    }
    a:focus{
        outline: none;              
    }
    &.-active{
        opacity: 1;
    }
    .-mountain-report-open &{
        z-index: 2;
        padding-top: 80px;
    }
}
.mountain-report-dropdown__wrapper{
    height: 100%;
    padding: 24px 24px 68px 24px;
    overflow-y: auto;
    overflow-x: hidden;
}
.mountain-report-dropdown__close-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    color: $white;
    background-color: transparent;
    cursor: pointer;
    border: none;
    padding: 20px;
}
.mountain-report-dropdown__close-btn-text{
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
}



// .mountain-report__close{
//     @include button-clear();
//     display: flex;
//     flex-direction: column;
//     padding: 20px;
// }
// .mountain-report__close-text{
//     color: $white;
//     font-size: 10px;
//     display: inline-block;
//     margin-top: 4px;
// }