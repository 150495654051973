&__result {
    @include display(flex, row wrap, space-between, center);
    width: 100%;
    padding: calc($comp-pad * 2) 0;

    &-list {
        @include display(flex, column nowrap);
        width: 100%;
        max-width: 100%;
    }

    &-copy {
        flex: 0 0 100%;
        max-width: 100%;
        @include gutter1;

        h3 {
            color: $ptc-intro-heading-color;
        }
    }

    &-media {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media all and (min-width: $bp-med) {
        flex-direction: row-reverse;

        &-copy {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 0;
        }

        &-media {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .glide__slide {
        height: auto;
        overflow: hidden;

        &s {
            align-items: stretch;
        }   
    }

    &s-text {
        width: 100%;
        text-align: left;
    }
}

&__no-results-text {
    width: 100%;
    margin-top: 0;
    text-align: left;
    @include font(rem(14px), null, $font-alert);
    color: $load-more__color;

    .filters__btn-reset {
        color: $load-more__color;
        text-decoration: underline;
    }
}

.js-shuffle-sizer {
    width: 2300px;
}