$interactive-map-breakpoint : $bp-lrg;
.interactive-map{
    margin: 0;
    @media all and (min-width: $interactive-map-breakpoint) {
        display: flex;
        flex-direction: row-reverse;
    }
    &.-full-width{
        @media all and (min-width: $interactive-map-breakpoint) {
            display: block;
        }
    }
}
.interactive-map__content{
    flex: 0 1 32%;
    background-color: $brand-primary;
    position: relative;
    border-radius: 25px 0px;
    padding: 25px;
    margin-bottom: -25px;
    z-index: 1;
    @media all and (min-width: $interactive-map-breakpoint) {
        margin-bottom: 0;
        padding: 70px 70px 70px 45px;
        border-radius: 0px 0px 25px;
    }
    &:after{
        @media all and (min-width: $interactive-map-breakpoint) {
            background-color: $brand-primary;
            border-radius: 25px 0px 0px;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            left: -25px;
            top: 0;
            width: 25px;
            z-index: 1;
        }
    }
}

.interactive-map__heading{
    color: $white;
    font-size: 45px;
    line-height: 1.1;
    margin: 0;
    @media all and (min-width: $interactive-map-breakpoint) {
        font-size: 64px;
    }

}
.interactive-map__items{
    margin: 0;
    padding: 20px 0;
    list-style-type: none;
    counter-reset: item;
    overflow: hidden;
    overflow-x: auto;
    @media all and (max-width: $interactive-map-breakpoint) {
        display: flex;
    }
}
.interactive-map__item{
    position: relative;
    margin: 20px 0;
    white-space: nowrap;
    @media all and (max-width: $interactive-map-breakpoint) {
        margin: 0px 10px;
    }
    &:before{
        content: counter(item, upper-alpha);
        display: inline-block;
        color: $brand-secondary;
        counter-increment: item;
        font-size: 10px;
        transform: translateY(-10px);
        margin-right: 8px;
        @media all and (min-width: $interactive-map-breakpoint) {
            font-size: 12px;
        }
    }
}
.interactive-map__item-link{
    color: $white;
    opacity: .5;
    display: inline-block;
    @media all and (min-width: $interactive-map-breakpoint) {
        padding: 15px 0;
    }
    &:hover,
    &.-active{
        color: $white;
        opacity: 1;
        text-decoration: none;
    }
}
.interactive-map__map{
    .ptc-two-col__map-container{
        padding-bottom: 0;
        height: calc(100% + 25px);
    }
    .gm-style .gm-style-iw-c{
        border-radius: 0px 25px;
        box-shadow: none;
        padding: 15px;
        h4{
            font-size: 14px;
            font-family: $font-alert;
        }
        a{
            @extend .cta-btn-secondary;
            font-size: 14px;
            color: $map-info-cta-color;
            padding-top: 0;
            padding-bottom: 0;
            &:before{
                display: none;
            }
            &:after{
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 6px solid $map-info-cta-color;
            }
            &:hover{
                color: $map-info-cta-color;
            }
        }
    }
    .poi-info-window .address{
        margin-bottom: 1rem;
    }
    [role="button"]{
        color: #fff;
    }
    .gm-ui-hover-effect{
        transform: translate(-10px, 10px);
    }
}

.interactive-map__map-container{
    background-color: $brand-primary--50;
    flex: 0 1 68%;
    height: 0;
    padding: 0 0 90% 0;
    position: relative;
    overflow: hidden;
    @media all and (min-width: $interactive-map-breakpoint) {
        font-size: 90px;
        padding: 0 0 57% 0;
    }
    iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }
}
.interactive-map__map{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}