.video{
    position: relative;
    padding-bottom: 0px;
    text-decoration: none;
    //min-height: 500px;
    &.-padded{
        //margin-bottom: 50px;
        @media all and (min-width: $bp-lrg) {
            margin-bottom: 100px;
        }
    }
    .weddings__result & {
        height: 100%;
    }

    figcaption {
        @include display(flex, row nowrap, null, center);
        padding: 1em 0;
        color: $white;

        &:after {
            @include triangle(right, $white, rem(5px));
            margin-left: 1em;
            transition: margin .35s ease-out;
        }
    }

    @include hover {
        figcaption {
            &:after {
                margin-left: 2em;
            }
        }
    }
}

a.video {
    display: block;
    margin: 1em 0;
}
.video__hgroup{
    @include gutter1();
    position: relative;

}
.video__link{
    display: block;
    .video.-padded &{
        @media all and (min-width: $bp-lrg) {
            margin: 0 $comp-pad-med;
        }
    }
    &:hover{
        text-decoration: none;
    }

    .weddings__result & {
        height: 100%;

        picture {

            img {
                @include position(absolute, null, (top, left), (50%, 50%), translate(-50%, -50%));
                width: auto;
                max-width: none;
                max-height: 100%;
            }

            &::after {
                content: '';
                @include display(block);
                overflow: hidden;
                padding-top: calc(666 / 500 * 100%);
    
                @media all and (min-width: $bp-sml) {
                    padding-top: calc(500 / 700 * 100%);
                }
    
                @media all and (min-width: $bp-med) {
                    padding-top: calc(1000 / 1000 * 100%);
                }
    
                @media all and (min-width: $bp-lrg) {
                    padding-top: calc(428 / 600 * 100%);
                }
    
                @media all and (min-width: $bp-xlrg) {
                    padding-top: calc(714 / 1000 * 100%);
                }
            }
        }
    }
}
.video__media{
    display: block;
    @include size(100%);
    position: relative;

    .weddings__result & {
        background: $brand-primary--5;

        
    }
    
}
.video__play-btn{
    position: absolute;
    z-index: 1;
    left: 50%;
    max-width: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    .-padded &{
        max-width: 20%;
        @media all and (min-width: $bp-lrg) {
            max-width: 168px;
        }
    }
}
.video__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media all and (max-width: $bp-lrg) {
        max-height: 720px;

        .weddings__result & {
            max-height: none;
        }
    }
}


.video__heading{
    max-width: 400px;
    margin: 50px 0 24px 0;
    @extend .h3;
    @media all and (min-width: $bp-lrg) {
        margin: 100px 0 30px 0;
    }
}
.video__caption{
    color: $white;
    font-size: 12px;
    padding: 12px 26px;
    background-color: $vid-caption-bg;
    width: 300px;
    position: absolute;
    margin: 0 40px;
    bottom: 0;
    transform: translate(0, 50%);
    right: calc(50% - 190px);
    @media all and (min-width: $bp-sml) {
        left: auto;
        transform: translate(0,50%);
        margin: 0;
        right: $comp-pad-lrg;
    }
    .video.-padded &{
        
        @media all and (max-width: $bp-lrg) {
            position: static;
            margin: 40px auto;
            transform: translateY(0);
        }
    }
}
.glightbox-clean .gnext,
.glightbox-clean .gprev{
    // HIDING BUTTONS DUE TO CREATIVE REQUEST
    // THIS MIGHT BREAK FUTURE FUNCTIONALITY
    display: none;
}