&__button {
    @include display(inline-flex, row nowrap, center);
    position: relative;
    max-width: rem(230px);
    padding: 1.1em 1.75em 1em;
    appearance: none;
    border-radius: 30px;
    white-space: nowrap;
    @include font(rem(14px), 1, $font-alert);
    border: 1px solid transparent;
    @include color($white, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color);
    transition: background-color .35s ease-out, color .35s ease-out, border-color .35s ease-out;

    @include hover {
        @include color($weddings__filter-ctrl-color, $white, transparent);
        text-decoration: none;
        cursor: pointer;
    }

    @media all and (max-width: $bp-sml) {
        @include display(flex);
        max-width: none;
    }

    &-reset {
        @include color($white, $weddings__filter-ctrl-color, transparent);

        @include hover {
            @include color($white, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color);
        }
    }

    &-group {
        @include display(flex, row nowrap);
        width: calc(100% + #{$weddings__button-group-gutter * 2});
        margin: 0 calc($weddings__button-group-gutter * -1);
        padding: $comp-pad 0;

        > .weddings__button {
            margin: $weddings__button-group-gutter;
        }

        &-center {
            justify-content: center;
        }

        &-stack {
            flex-flow: column nowrap;
        }
    }

    &-arrow-right {
        padding-right: 2.5em;
        &:after {
            @include triangle(right, $weddings__filter-ctrl-color, .3em);
            @include position(absolute, null, (top, right), (50%, 1.25em), translateY(-50%));
        }
    }
}