@import "../../node_modules/hotel-datepicker/dist/css/hotel-datepicker.css";

.datepicker {
    border-radius: rem(30px);
    font-family: $font-paragraph;
    color: $filters__color;
    z-index: 2;

    @media all and (max-width: 480px) {
        left: 50%;
        transform: translateX(-50%);
    }

    @media all and (max-width: 768px) {
        width: rem(340px);

        .datepicker__month {
            width: rem(300px);

            &--month2 {
                margin-top: rem(16px);
            }
        }
        .datepicker__months {

            &:before {
                content: none;
            }
        }
        .datepicker__topbar {
            padding-top: 0;
        }
    }

    &__month {
        
        &-caption {
            border: 0;
        }

        &-name {
            text-transform: none;
        }

        &-day {
            border: 0;
            transition: none;

            &--today {
                background: rgba($filters__color, .25);
                color: $filters__color;
                font-weight: bold;

                &.datepicker__month-day--selected {
                    background: $filters__color;
                    color: $white;
                }

                &.datepicker__month-day--hovering {
                    color: $white;
                }
                
            }

            &--selected {
                background-color: rgba($filters__color, .5);

                &:first-child {
                    border-radius: rem(50px) 0 0 rem(50px);

                    &::after {
                        border-radius: rem(50px) 0 0 rem(50px);
                    }
                }

                &:last-child {
                    border-radius: 0 rem(50px) rem(50px) 0;

                    &::after {
                        border-radius: 0 rem(50px) rem(50px) 0;
                    }
                }

                @include hover {
                    background: none;
                }
            }

            &--first-day-selected,
            &--last-day-selected {
                border-radius: rem(50px);
                background-color: $filters__color;
                color: $white;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    @include position(absolute, -1, (top, left, right, bottom), (0, 0, 0, 0));
                    border-radius: rem(50px);
                    background: $filters__color;
                }

                &:first-child {
                    border-radius: rem(50px);

                    &::after {
                        border-radius: rem(50px) 0 0 rem(50px);
                    }
                }

                &:last-child {
                    border-radius: rem(50px);

                    &::after {
                        border-radius: 0 rem(50px) rem(50px) 0;
                    }
                }
            }

            &--first-day-selected {

                &::after {
                    border-radius: 0 rem(50px) rem(50px) 0;
                }

                @include hover {
                    border-radius: rem(50px);
                }

                &:last-child::after {
                    border-radius: rem(50px);
                }

                &:not(:last-child)::after {
                    border-radius: rem(50px) 0 0 rem(50px);
                }
            }

            &--last-day-selected {

                &::after {
                    border-radius: 0 rem(50px) rem(50px) 0;
                }

                &:first-child::after {
                    border-radius: rem(50px);
                }
            }

            

            &--hovering {
                background: rgba($filters__color, .5);
                color: $white;

                &.datepicker__month-day--first-day-selected::after,
                + .datepicker__month-day--first-day-selected::after {
                    border-radius: 0 rem(50px) rem(50px) 0;
                }
            }

            @include hover {
                background: rgba($filters__color, .5);
                color: $white;
            }
        }
    }
    &__tooltip {
        border-radius: rem(30px);
        background: $filters__color;
        color: $white;

        .filters & {
            display: none;
        }
    }
    &__info {
        @include sr-only;
    }
    &__buttons {

        button {
            @include display(inline-flex, row nowrap, center, center);
            border-radius: rem(30px);
            text-transform: none;
            font-size: rem(12px);
            line-height: 1;
            letter-spacing: 0.05em;

            &:not([disabled]) {
                background: $booking-bar-bg;
            }
        }
    }
    .filters & {
        width: calc(100% + rem(14px));
        margin: 0 rem(-7px);
        padding: rem($comp-pad) 0;
        box-shadow: none;
        border-radius: 0px;

        table {
            width: 100%;

            @media all and (min-width: rem(479.9px)) {
                width: calc(50% - #{rem($comp-pad)});
            }
        }
        &__inner {
            padding: 0;
        }
    }
}