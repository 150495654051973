.rich-text{
    margin: 50px 0;
    @media all and (min-width: $bp-lrg) {
        margin: 100px 0;
    }
}
.rich-text__wrapper{
    @include gutter2();
}
.rich-text__content{
    font-size: 14px;
    .rich-text.-three-quarter &{
        
        @media all and (min-width: $bp-lrg) {
            max-width: 75%;
        }
        blockquote{
            @media all and (min-width: $global-desktop-break) {
                width: 110%;
                padding: 76px 10%;
                transform: translateX(-5%);
            }
        }
    }
    h2{
        color: $font-h1-color;
    }
    h3{
        color: $font-h2-color;
    }
    p + h2{
        margin-top: 55px;
    }
    img{
        display: block;
        margin: 50px auto;
    }
    blockquote{
        padding: 48px 0;
        margin: 50px 0;
        &:before,
        &:after{
            content:"";
            display: block;
            width: 100%;
            height: 2px;
            background-color: $brand-primary;
            opacity: $bq-opacity;
            position: absolute;
            left: 0;
        }
        &:before{
            top: 0;
        }
        &:after{
            bottom: 0;
        }
        @media all and (min-width: $global-desktop-break) {
            margin: 60px auto;
            width: 89.6vw;
            padding: 76px 10%;
            transform: translateX(-5.2%);
        }
    }
}