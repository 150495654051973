&__alert {
    @include display(flex, row nowrap);
    font-size: rem(11px);

    a {
        color: $white;
        text-decoration: underline;
        transition: color .35s ease-out;

        @include hover {
            color: $brand-secondary;
        }
    }

    .header.-mountain-report-open .header__utility-nav .mc-weather &,
    .mc-weather__widget & {

        svg {
            flex: 0 0 auto;
            margin-right: .5em;
            margin-top: .15em;

            path {
                fill: $brand-secondary;
                stroke-width: 0px;
            }
        }
    }
}