@at-root {
  :root {
    /*
  --swiper-scrollbar-border-radius: 10px;
  --swiper-scrollbar-top: auto;
  --swiper-scrollbar-bottom: 4px;
  --swiper-scrollbar-left: auto;
  --swiper-scrollbar-right: 4px;
  --swiper-scrollbar-sides-offset: 1%;
  --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(0, 0, 0, 0.5);
  --swiper-scrollbar-size: 4px;
  */
  }
}
.swiper-scrollbar {
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  position: relative;
  -ms-touch-action: none;
  //background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1));
  background: $brand-primary--5;
  .swiper-scrollbar-disabled > &,
  &.swiper-scrollbar-disabled {
    display: none !important;
  }
  .swiper-horizontal > &,
  &.swiper-scrollbar-horizontal {
    //position: absolute;
    // left: var(--swiper-scrollbar-sides-offset, 1%);
    // bottom: var(--swiper-scrollbar-bottom, 4px);
    // top: var(--swiper-scrollbar-top, auto);
    z-index: 50;
    height: var(--swiper-scrollbar-size, 2px);
    //width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
    width: 100%;
  }
  .swiper-vertical > &,
  &.swiper-scrollbar-vertical {
    position: absolute;
    left: var(--swiper-scrollbar-left, auto);
    right: var(--swiper-scrollbar-right, 4px);
    top: var(--swiper-scrollbar-sides-offset, 1%);
    z-index: 50;
    width: var(--swiper-scrollbar-size, 4px);
    height: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  }
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  //background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, 0.5));
  background: $swiper__scrollbar-color;
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}
