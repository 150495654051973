&__filter {
    @include display(inline-flex);
    position: relative;
    margin: rem(4px);
    border-radius: 30px;
    background: $weddings__filter-select-background-color;
    color: $weddings__filter-select-text-color;

    &:after {
        @include triangle(bottom, $white, .3em);
        position: absolute;
        top: 52%;
        right: calc($weddings__button-icon-spacing / 2);
        transform: translateY(-50%);
    }

    &.focused {
        @include color($brand-primary);
    }

    &-group {
        padding: $header-height $comp-pad calc($comp-pad * 2);
    }

    &-sentence {
        margin: 0;
        padding-bottom: $comp-pad;
        text-align: center;
        @include font(rem(20px), 1.5, $font-h2);

        @media all and (min-width: $bp-lrg) {
            br {
                display: none;
            }
        }
    }

    &-select {
        @include display(inline-flex);
        padding: .75em #{$weddings__button-icon-spacing} .65em #{calc($weddings__button-icon-spacing / 2)};
        appearance: none;
        outline: none;
        border: 0;
        cursor: pointer;
        font-family: $font-h2;
        @include color(transparent, $weddings__filter-select-text-color);
        transition: background-color .35s ease-out, color .35s ease-out;

        @include hover {
            @include color($weddings__filter-select-background-color);
        }
        option {
            font-size: .8em;
            @include color($white, $brand-primary);

            &:checked {
                @include color($weddings__filter-select-option-highlight-color, $white);
            }
        }
        
    }
}