$nav-break : $bp-lrg;
.local-nav{
    margin: 2rem 24px;
    @media all and (min-width: $nav-break) {
        margin: 2rem auto;
        max-width: 80%;
    }
    &.-desktop{
        @media all and (min-width: $nav-break) {
            margin: 2rem 0;
            max-width: 100%;
        }
    }
}
.local-nav__mobile-toggle{
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-family: $font-alert;
    text-align: center;
    padding: 12px 62px;
    border: 1px solid $nav-color;
    color: $nav-color;
    border-radius: 30px;
    background-color: $white;
    width: 100%;
    margin: 0 0 12px;
    transition: all .5s;
    &.-item-active{
        background-color: $nav-color;
        color: $white;
    }
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            display: none;
        }
    }
} 
.local-nav__wrapper{
    //@include gutter2();
    //overflow-x: hidden;
    background-color: $nav-dropdown-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    overflow: hidden;
    height: 0;
    padding: 0;
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            background-color: transparent;
            box-shadow: none;
            border-radius: 0;
            height: auto;
            overflow: visible;
            padding: 0 $comp-pad-med;
        }
    }
}
.local-nav__list{
    @include list-fix();
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 -20px;
        }
    }
}
.local-nav__item{
    flex: 0 1 100%;
    margin: 0;
    position: relative;
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            margin: 0 20px;
        }
    }
}
.local-nav__link{
    display: block;
    color: $subnav-color;
    font-size: 14px;
    font-family: $font-alert;
    padding: 12px 24px;
    white-space: nowrap;
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            text-align: center;
            padding: 8px 62px;
            border: 1px solid $nav-color;
            color: $nav-color;
            border-radius: 30px;
            background-color: $white;
        }
    }
    &:hover,
    .local-nav.-desktop &:hover{
        color: $white;
        background-color: $nav-color;
        text-decoration: none;
    }
    .local-nav.-desktop &.-item-active,
    &.-item-active{
        background-color: $nav-color;
        color: $white;
    }
}


.local-nav__link-plus{
    display: inline-block;
    width: 9px;
    height: 2px;
    background-color: $subnav-color;
    position: relative;
    left: 8px;
    top: -5px;
    transition: all .35s;
    .local-nav__mobile-toggle &{
        background-color: $nav-color;
        &:after{
            background-color: $nav-color;
        }
    }
    .local-nav.-desktop &{
        background-color: $nav-color;
        &:after{
            background-color: $nav-color;
        }
    }
    &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        background-color: $subnav-color;
    }
    .local-nav__link:hover &,
    .local-nav__link.-item-active &,
    .local-nav__mobile-toggle.-item-active &{
        background-color: $white;
        &:after{
            background-color: $white;
        }
    }
    .-item-active &:after{
        transform: rotate(0deg);
    }
}
.local-nav__dropdown{
    
    overflow: hidden;
    height: 0;
    //opacity: 0;
    transition: opacity .35s;
    min-width: 100%;
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            background-color: $nav-dropdown-color;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            position: absolute;
            left: 0;
            top: 55px;
            z-index: 2;
        }
    }
    &.-item-active{
        opacity: 1;
    }
}
.local-nav__dropdown-list{
    @include list-fix();
    padding: 0 24px;
}
.local-nav__dropdown-item{
    margin: 0 ;
    padding: 0;
}
.local-nav__dropdown-link{
    display: block;
    padding: 16px;
    font-size: 14px;
    color: $subnav-color;
    &:hover{
        color: $nav-color;
    }
    &.-item-active{
        background-color: $nav-color;
        color: $white;
    }
}

.local-nav__subnav{
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity .35s;
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            background-color: $brand-primary;
            position: absolute;
            left: 34%;
            top: 0;
            width: 0%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            //padding: 75px 0 75px;
            //transition: width .35s;
            z-index: 2;
        }
    }

    &.-item-active{
        opacity: 1;
    }
}
.local-nav__subwrapper{
    transition: opacity .35s ease-in .35s;
    opacity: 0;
    .-item-active &{
        opacity: 1;
    }
    .local-nav.-desktop &{
        @media all and (min-width: $nav-break) {
            //margin-top: 225px;
            margin-left: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

