.trail-map{
    position: relative;
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
}

.trail-map__wrapper{
    @include gutter1();
}

.trail-map__hgroup{
    @media all and (min-width: $bp-lrg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.trail-map__heading{
    color: $brand-secondary;
}

.trail-map__img-container{
    position: relative;
}

.trail-map__lightbox-link {
    display: block;
    position:absolute;
    padding: 15px;
    top:30px;
    left: 0px;
    background-color: $brand-primary;
    border-radius: 0px 20px 20px 0px;
}