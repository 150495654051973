.filters {

    &__btn {
        @include display(inline-flex, row nowrap, center);
        @include position;
        max-width: rem(230px);
        padding: 1em 1.75em 1em;
        appearance: none;
        border-radius: rem(30px);
        white-space: nowrap;
        @include font(rem(14px), 1, $font-alert);
        border: 1px solid transparent;
        @include color(transparent, $filters__color, $filters__color, $filters__color, $filters__color, $filters__color);

        .filters__callout-footer & {
            color: $white;

            &-submit {
                border-color: $white;
            }
        }
        transition: background-color .35s ease-out, color .35s ease-out;

        @include hover {
            @include color($filters__color, $white, transparent);
            text-decoration: none;
            cursor: pointer;
        }
        &-submit {

            @include hover {
                @include color($white, $filters__color, $white);
            }
        }
        &-reset {

            @include hover {
                text-decoration: underline;
            } 

            .shuffle__no-results-text & {
                appearance: none;
                background: none;
                border: 0;
                padding: 0;
                font-weight: 700;
                color: $brand-primary;
                text-decoration: underline;
                cursor: pointer;

                @include hover {
                    text-decoration: none;
                }
            }
        }
        &-close {
            padding: 0;
            border: 0;
            margin-left: rem($comp-pad);
            transition: opacity .25s ease-out;

            @include hover {
                opacity: .5;
            }
        }
        &-trigger {
            @include display(flex);
            margin-left: auto;

            svg {
                margin-left: 1em;

                path {
                    transition: fill .35s ease-out;
                }
            }
            @include hover {
                @include color($filters__color, $white, null, $white, $white, $white);
            }
        }

        .glightbox-container & {
            padding: 1em 1.75em 1em;
        }
    }
    &__callout {
        width: 100%;
        //max-width: rem(830px);
        margin: 0 auto;
        overflow: hidden;

        @media all and (min-width: $bp-med) {
            border-radius: rem(25px);
        }
        &-section {
            padding: rem(25px) 0;
            border-bottom: 1px solid rgba($filters__color, .5);

            &:last-child {
                border: 0;
            }
        }
        &-header,
        &-footer {
            @include color($filters__color, $white, null, $white, $white, $white);
        }
        &-header {
            @include display(flex, row nowrap, space-between, center);
            @include position(relative, 3);
            padding: rem(25px) rem(30px) rem(20px);
            border: 0;

            p {
                margin: 0;
            }
        }
        &-footer {
            @include display(flex, row wrap, center, center);
            padding: rem(calc($comp-pad / 2));
        }
        &-main {
            padding: 0 rem(30px);
            @include color($white, $brand-primary);
        }
    }
    &__checkbox {
        flex: 0 0 100%;
        max-width: 100%;
        
        @media all and (min-width: $bp-sml) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include position;
        padding: rem(calc($comp-pad / 4));

        input[type="checkbox"] {
            @include sr-only;

            + label {
                @include display(flex, row nowrap);
                cursor: pointer;
                
                &:before {
                    content: '';
                    @include display(inline-block);
                    flex: 0 0 rem(25px);
                    vertical-align: middle;
                    @include size(rem(25px));
                    max-width: rem(25px);
                    margin: -.05em 1em .05em 0;
                    border: 1px solid $filters__color;
                    background: $white center no-repeat;
                    transition: all .25s ease-out;
                }
                @include hover {

                    &:before {
                        background: $filters__color center no-repeat;
                    }
                }
            }
            &:checked + label:before {
                background: $filters__color url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
                background-size: rem(20px);
            }
        }
        &es {
            @include display(flex, row wrap);
            width: calc(100% + rem(calc($comp-pad / 2)));
            margin: rem($comp-pad) rem(calc($comp-pad / -4));
        }
    }
    &__radio{
        display: flex;
        align-items: center;
        flex: 0 0 100%;
        max-width: 100%;
        
        @media all and (min-width: $bp-sml) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include position;
        padding: rem(calc($comp-pad / 4));

        input[type="radio"] {
            appearance: none;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            border: 1px solid $filters__color;
            transition: 0.2s all linear;
            margin-right: 10px;
            + label {
                cursor: pointer;
            }
            &:checked {
                border: 8px solid $filters__color;
            }
        }
        &s {
            @include display(flex, row wrap);
            width: calc(100% + rem(calc($comp-pad / 2)));
            margin: rem($comp-pad) rem(calc($comp-pad / -4));
        }
    }
    &__toggle {
        flex: 0 0 auto;
        @include position;
        @include size(rem(60px), rem(30px));
        border-radius: rem(30px);
        background: rgba($filters__color, 0.5);
        transition: background-color .25s ease-out;

        &:after {
            content: '';
            @include display(block);
            @include position(absolute, 1, (top, left), (0, 0));
            @include size(rem(29px));
            border-radius: 50%;
            border: 1px solid $filters__color;
            background: $white;
            transition: transform .25s ease-out;
        }
        &-label {
            @include display(block);
            padding-right: 2em;

            @media all and (min-width: $bp-sml) {
                flex: 0 0 75%;
                max-width: 75%;
            }
            @media all and (min-width: $bp-med) {
                flex: 0 0 60%;
                max-width: 60%;
            }
            strong {
                @include display(inline-block);
                margin-bottom: rem(calc($comp-pad / 2));
                font-family: $font-h4;
                font-weight: 700;

                &:only-child {
                    margin-bottom: 0;
                }
            }
        }
        &-wrap {
            @include position;
            padding: rem($comp-pad) 0;

            input[type="checkbox"] {
                @include sr-only;
    
                + label {
                    @include display(flex, row nowrap, space-between, center);
                    cursor: pointer;
                }
                &:checked + label .filters__toggle {
                    background: $filters__color;

                    &:after {
                        transform: translateX(rem(30px));
                    }
                }
            }
        }
    }
    &__count {
        @include display(flex, row nowrap, center, center);
        @include position(absolute, 1, (top, right), (0, 0), translate(50%, -50%));
        @include size(rem(32px));
        border-radius: 50%;
        @include color($filters__color, $white, null);
        @include font(rem(12px), 1, $font-paragraph, 500);

        &:empty {
            @include display(none);
        }
    }
    .select {

        &__icon {
            @include display(inline-block);
            @include position(absolute, null, (top, right), (50%, rem(15px)), translate(-50%, -50%));
            @include size(rem(16px), rem(2px));
            background: $white;
            transition: all .35s;
            
            &:after {
                content: '';
                @include display(block);
                @include position(absolute, null, (top, left), (0, 0), rotate(90deg));
                @include size(100%);
                background: $white;
                transition: all .35s;
            }
        }
        &__wrap {
            @include position;
            width: rem(220px);

            .weddings & {
                width: auto;
            }
            margin-left: auto;

            &.-open {

                .select__icon:after {
                    transform: rotate(0deg);
                }
            }
        }
        &__native,
        &__custom {
            @include display(inline-flex);
            @include position;
            width: rem(220px);

            .weddings & {
                width: auto;
            }
            margin: rem(4px);
            border-radius: 30px;
        }
        &__native,
        &__faux {
            margin: 0;
            padding: rem(calc($comp-pad / 2)) rem(calc($comp-pad * 2)) rem(calc($comp-pad / 2)) rem($comp-pad);
            appearance: none;
            border-radius: rem(30px);
            outline: none;
            border: 1px solid $white;
            background: transparent;
            line-height: 1.5;
            color: $white;
            cursor: pointer;
            @media all and (max-width: $bp-lrg) {
                padding: 0.5rem 3rem 0.5rem 1rem;
            }
        }
        &__custom {
            @include display(none);
            @include position(absolute, 1, (top, left, right), (0, 0, 0));
            width: 100%;
            @include list-fix();

            a {
                @include display(block);
                max-height: 100vh;
                padding: rem(calc($comp-pad / 2)) rem($comp-pad);
                text-decoration: none;

                &.select__faux {

                    @include hover {
                        color: $white;
                    }
                }
                &:hover {
                    color: $filters__color;
                    text-decoration: underline;
                }
                &:focus {
                    color: $white;
                }
            }
            li {
                margin: 0;
                padding: 0;

                + li {
                    border-left: 1px solid $gray;
                    border-right: 1px solid $gray;
                    background: $white;

                    a {
                        color: $filters__color;
                    }
                }
                &:first-child {
                    background:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTUgN0wxIDNIOUw1IDdaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==") no-repeat scroll 0 0 transparent;
                    background-position: center;
                    background-size: cover;

                    a {

                        @include hover {
                            text-decoration: none;
                        }
                    }
                }
                &:nth-child(2) {
                    margin-top: rem(calc($comp-pad / 4));
                    padding-top: rem(calc($comp-pad / 2));
                    border-radius: rem(30px) rem(30px) 0 0;
                }
                &:last-child {
                    padding-bottom: rem(calc($comp-pad / 2));
                    border-radius: 0 0 rem(30px) rem(30px);
                    border-bottom: 1px solid $gray;
                    box-shadow: 0 6px 2px -2px rgba($gray, .85);
                }
                &:first-child + li:last-child {
                    border-radius: rem(30px);
                }
            }
        }
        &__native {

            @media (hover: hover) {

                &:focus {
                    visibility: hidden;
                }

                &.-focused {
                    //transform: translateX(100vw);
                    
                    + .select__custom {
                        @include display(block);
                        //pointer-events: none;

                        .select__faux {
                            background: darken($weddings__filter-select-background-color, 10%);
                        }

                        a {
                            //pointer-events: auto;
                        }
                    }
                }
            }
        }
    }
    .ginlined-content & {
        height: 100%;

        &__callout {
            @include display(flex, column nowrap);
            height: 100%;

            &-header,
            &-footer {
                flex: 0 0 auto;
            }
            &-main {
                flex: 1;
                overflow: auto;
            }
        }
    }
}

.filters__wrap,
.results__wrap {
    padding: rem($comp-pad);
    @media all and (min-width: $bp-xlrg) {
        padding: rem(calc($comp-pad * 2)) $comp-pad-med;
    }
}

.results__wrap {

    @media all and (max-width: $bp-lrg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.filters__wrap {
    @include display(flex, row nowrap, space-between, center);
    padding-top: 0;

    + .custom-card {
        margin-top: 0;
    }
}

.weddings__filter .select {
    
    &__icon {
        display: none;
    }

    &__custom {
        text-align: left;

        a {
            text-decoration: none;
        }
    }
}

.glightbox-filters {

    .gcontainer {

        .gslider {
            overflow: auto;
        }
        .gslide-inline {
            border-radius: rem(25px) 0 0 rem(25px);
            overflow: hidden;
            background: none;
            box-shadow: none;
    
            .ginlined-content {
                padding: 0;
            }
        }
        .gslide.current {
            position: absolute;
        }
    }
}

.shuffle__results-text,
.shuffle__count,
.shuffle__no-results-text,
.shuffle__btn {

    &:not(.-active) {
        display: none;
    }
}

.shuffle__no-results-text {
    text-align: center;
}

.shuffle {
    
    &__sizer,
    &__result {
        width: 100%;
    
        @media all and (min-width: $bp-med) {
            flex: 0 0 50%;
            max-width: 50%;
    
            .weddings & {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        @media all and (min-width: $bp-xlrg) {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
        }
    }

    &__sizer {
        margin-left: rem(1.5px);
    }

    &__result {
        padding: rem($comp-pad);

        &s {
            @include display(flex, row wrap, space-between);
            width: 100%;

            @media all and (min-width: $bp-lrg) {
                width: calc(100% + #{rem(calc($comp-pad * 2))});
                margin: 0 rem(calc($comp-pad * -1)) 3em;
            }
            @media all and (max-width: $bp-lrg) {
                
                &.custom-card__list {
                    margin: 0;
                }
            }
            @include list-fix;

            > * {

                &.-hidden {
                    @include display(none);
                }
            }
        }
    }

    &__btn {
        @include display(flex, row nowrap, center);
        @include position;
        max-width: rem(230px);
        margin: 0 auto;
        padding: 1.1em 1.75em 1em;
        appearance: none;
        border-radius: 30px;
        white-space: nowrap;
        @include font(rem(18px), 1, $font-alert);
        border: 1px solid $load-more__color;
        @include color($white, $load-more__color);
        transition: background-color .35s ease-out, color .35s ease-out;

        @include hover {
            text-decoration: none;
            cursor: pointer;
            @include color($load-more__color, $white);
        }
    }

    &__count {
        margin: 0 0 1.5em;
        text-align: center;
        @include font(rem(14px), null, $font-alert);
        color: $load-more__color;
    }
}