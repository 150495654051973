.two-image-side-by-side__img-overlay-wrap img { 
    display: block;
    width: 100%;
    height: auto;
 }
 
 .two-image-side-by-side__img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 2em;
    width: 50%;
    height:66%;
    @media all and (max-width: 550px) {
        position: absolute;
        top: 0;
        left: 0em;
        width: 100%;
        height: 50%;
        transform: scale(.5);  
    }
}

.two-image-side-by-side__img-overlay-wrap-sb svg {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 61%;
    height: 27%;
}

.two-image-side-by-side__widget {
    display:flex;
    //align-items: center;
    &.-padded {
        margin: 0 0 100px 0;
    }
    @media all and (max-width: 550px) {
        display:inline-block;
        width: 100%;
    }
}
.two-image-side-by-side__img-overlay-wrap, .two-image-side-by-side__img-overlay-wrap-sb {
    position: relative;
    display: flex; 
    &.-border {
        padding: 10%;
        height: 100%;
        @media all and (max-width: 550px) {
            padding: 20%;
        }
    }
    &.-border-sb {
        padding: 13% 18% 11% 2%;
        height: auto;
        @media all and (max-width: 550px) {
            padding: 20%;
        }
    }
    &.-full-image{
        width: 100%;

    }
    &.-full-image-sb-right{
        width: 150%;
        padding: 4em 4em 4em 0em;
        height: auto;
        @media all and (max-width: 550px) {
            width: 100%;
            padding: 2em 2em 2em 2em;
            height: auto;
        }

    }
    &.-full-image-sb-left{
        width: 150%;
        padding: 4em 0em 4em 4em;
        height: auto;
        @media all and (max-width: 550px) {
            width: 100%;
            padding: 2em 2em 2em 2em;
            height: auto;
        }

    }
    &.-small-image-sb{
        width: 100%;
        padding: 10em 5em 5em 5em;

        @media all and (max-width: 550px) {
            height: 333px;
            padding: 30% 10% 10% 40%;
        }

        @media all and (max-width: 321px) {
            padding: 34% 10% 10% 30%;
        }
        img{
            align-self: center;
            width: 100%;
        }
    }
    &.-navy-bg{
        background:#002856;
    }
    &.-gray-bg{
        background:#8094AA
    }
    &.-yellow-bg{
        background:#FCCF45
    }
    &.-dark-blue-bg {
        background: #1D4F91;
    }
}
  
 
