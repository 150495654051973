.feature-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 40px 0;
    min-height: 100vh;
    overflow: hidden;
    @media all and (min-width: $bp-lrg) {
        padding: 60px 0;
    }
    &.-blue{
        background-color: $feature-blue;
    }
    &.-gold{
        background-color: $feature-gold;
    }
    &.-offwhite{
        background-color: $feature-lightbg;
    }
    &.-slim{
        min-height: 60vh;
        padding: 20px 0;
        @media all and (min-width: $bp-lrg) {
            padding: 40px 0;
        }
    }
}
.feature-txt__wrapper{
    padding: 0 8.5%;
    @media all and (min-width: $bp-lrg) {
        //padding: 0 32%;
    }
}
.feature-txt__graphic{
    display: none;
    @media all and (min-width: $bp-lrg) {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        max-height: 100%;
    }
    svg path{
        fill: $feature-graphic-default;
        mix-blend-mode: multiply;
        opacity: 0.25;
    }
    .feature-txt.-blue &{
        background-color: $feature-blue;
        svg path{
            opacity: 0.5;
            fill: $feature-blue;
        }
    }
    .feature-txt.-gold &{
        background-color: $feature-gold;
        svg path{
            fill: $feature-gold;
        }
    }
}
.feature-txt__content{
    margin: 0 auto;
    max-width: 530px;
    @media all and (min-width: $bp-xlrg) {
        max-width: 43%;
    }
    .feature-txt.-large-txt &{
        max-width: 760px;
        @media all and (min-width: $bp-xlrg) {
            max-width: 62%;
        }
    }
}
.feature-txt__copy{
    color: $brand-primary;
    position: relative;
    z-index: 1;
    .feature-txt.-blue &{
        color: $white;
    }
    .feature-txt.-gold &{
        color: $feature-copy-gold;
    }
    .feature-txt.-offwhite &{
        color: $feature-gold;
    }
    .feature-txt.-large-txt &{
        font-family: $large-font;
        @media all and (min-width: $bp-lrg) {
            font-size: 64px;
        }
    }
}
.feature-txt__meta{
    font-family: $font-alert;
    margin-top: 30px;
    text-align: center;
    .feature-txt.-blue &{
        color: $white;
    }
    .feature-txt.-gold &{
        color: $feature-copy-gold;
    }
    .feature-txt.-offwhite &{
        color: $feature-gold;
    }
}