@import 'icon/var';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kas213') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kas213') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kas213##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

&__icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include position;
  font-size: 2em;

  &:before {
    @include position(relative, 1);
  }
  &:after {
    content: '';
    display: block;
    @include position(absolute, null, (top, left), (50%, 50%), translate(-50%, -50%));
    @include size(24px);
    border-radius: 50%;
  }

  .mc__key &,
  .mc__table-cell & {
    width: rem(40px);
  }
  .mc__key & {
    margin-right: .5em;
    text-align: center;
  }
  .mc__table-cell & {
    @include display(flex, row nowrap, center, center);
  }
  .mc__table-cell:not(.mc__table-cell-svg) & {
    margin-right: .25em;
  }
}

&__icon-open,
&__icon-closed,
&__icon-expected,
&__icon-scheduled,
&__icon-groomed {

  &:after {
    background: $white;
  }
}

&__icon-open {
  &:before {
    content: $icon-open;
    color: #499F55;
  }

}
&__icon-closed {
  &:before {
    content: $icon-closed; 
    color: #B52020;
  }
}
&__icon-hold {
  &:before {
    content: $icon-hold; 
    color: #FFC629;
  }
  &:after {
    background: $brand-primary;
  }
}
&__icon-expected,
&__icon-scheduled {
  &:before {
    content: $icon-expected; 
  }
}
&__icon-delayed {
  &:before {
    content: $icon-delayed; 
  }
}
&__icon-easy {
  &:before {
    content: $icon-easy;
    font-size: .8em;
    color: #499F55;
  }
}
&__icon-moderate {
  &:before {
    content: $icon-moderate; 
    font-size: .8em;
    color: #1E70BD;
  }
}
&__icon-difficult {
  &:before {
    content: $icon-difficult; 
    color: #000;
  }
}
&__icon-most-difficult {
  &:before {
    content: $icon-most-difficult;
    color: #000;
  }
}
&__icon-hiking {
  &:before {
    content: $icon-hiking; 
  }
}
&__icon-biking {
  &:before {
    content: $icon-biking; 
  }
}
&__icon-gondola {
  &:before {
    content: $icon-gondola; 
  }
}
&__icon-chair {
  &:before {
    content: $icon-chair; 
  }
}
&__icon-magic-carpet {
  &:before {
    content: $icon-magic-carpet; 
  }
}
&__icon-snow {
  &:before {
    content: $icon-snow; 
  }
}
&__icon-groomed {
  &:before {
    content: $icon-groomed; 
  }
}
&__icon-terrain-park {
  &:before {
    content: $icon-terrain-park;
    font-size: .7em;
    color: #FF8F51;
  }
}
&__icon-mixed-use {
  &:before {
    content: $icon-mixed-use;
  }
}