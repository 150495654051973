$alert-break : $bp-lrg;

.alert {
  position: absolute;
  top: 80px;
  left: 10px;
  width: calc(100% - 20px);
  background-color: $alert-yellow;
  z-index: 3;
  border-radius: 30px;
  padding: 10px;

  .mc-hero+& {
    @include position(relative, null, top, auto);
    margin-top: rem(calc($comp-pad * -1));
  }

  &.-green {
    background-color: $alert-green;
  }

  &.-red {
    background-color: $alert-red;
  }

  &.-blue {
    background-color: $alert-blue;
  }

  &.-lblue {
    @include color($alert-lblue, $white, null, $white, $white, $white);
  }
}

.alert__content {
  display: flex;

  .alert.-red &,
  .alert.-blue &,
  .alert.-green & {
    color: #fff;
  }
}

.alert__content-icon {
  margin: 2px 10px 4px;

  svg path {
    fill: $alert-copy-color;
  }

  .alert.-red &,
  .alert.-blue &,
  .alert.-green & {
    svg path {
      fill: #fff;
    }

  }
}

.alert__content-copy {
  padding-top: 8px;
  padding-right: 16px;
  font-size: 12px;

  p {
    margin: 0;
  }

  .alert.-red &,
  .alert.-blue &,
  .alert.-green & {
    color: #fff;

    a,
    a:hover {
      color: #fff;
      text-decoration: underline;
    }

  }
}

.alert__dropdown {
  border-radius: 25px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  top: 75px;
  width: 100%;
  max-width: 375px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity .35s, filter .35s;
  z-index: 3;

  @media all and (min-width: $menu-break) {
    min-width: 375px;
  }

  &.-active {
    height: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    opacity: 1;
    transform: translateX(-50%);

  }
}

.alert__dropdown-wrapper {
  background-color: $white;
  padding: 24px;

}

.alert__dropdown-list {
  @include list-fix();
}

.alert__dropdown-list-item {
  margin: 0;
  padding: 12px 0 12px 0;
  display: flex;
  height: auto;
  transform: translateX(0);
  opacity: 1;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid $brand-primary--50;
  transition: transform .5s ease-out, opacity .5s ease-out, padding .5s ease-out;
  overflow: hidden;

  &.-dismissed {
    opacity: 0;
    transform: translateX(100%);
    padding: 0;
  }

  &.-not-needed {
    opacity: 0;
    transform: translateX(-100%);
    padding: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  .header .alert__dropdown & {

    .alert__dropdown-icon,
    .alert__dropdown-item-dismiss {
      svg {
        path {
          fill: $brand-primary;
        }
      }
    }
  }
}

.alert__dropdown-content {
  font-size: 12px;
  padding: 4px 10px 8px;
  flex: 0 1 90%;

  p {
    margin: 0;
  }

  strong {
    font-family: $font-alert;
  }
}

.alert__dropdown-content-meta {
  color: $brand-primary--50;
  margin-top: 18px;
}

.alert__dropdown-item-dismiss {
  @include button-clear();
  padding-top: 10px;
}

.alert__dropdown-ctrls {
  padding: 16px 0 0 0;
  display: flex;
  justify-content: flex-end;
}

.alert__dropdown-ctrl-clear-btn,
.alert__dropdown-ctrl-close-btn {
  @include button-clear();
  font-size: 14px;
  color: $ctrl-color;
  padding: 12px 20px;
}

.alert__dropdown-ctrl-close-btn {
  border: 1px solid $ctrl-color;
  border-radius: 25px;
}

.alert__dropdown-ctrl-clear-btn {
  transition: opacity .35s;
  opacity: 1;

  &.-hidden {
    opacity: 0;
  }
}