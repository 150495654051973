&-weather {
    background: $brand-primary;
    color: $white;

    svg path {
        //stroke: $white;
    }

    &__wrap {
        padding: rem($comp-pad);
        @media all and (min-width: $bp-xlrg) {
            padding: rem(calc($comp-pad * 2)) $comp-pad-lrg;
        }

        .mountain-report-dropdown & {
            @media all and (max-width: $bp-med) {
                padding: 0 0 rem(60px);
            }
        }
    }

    @media all and (max-width: $bp-med) {

        + .mc-forecast {
            padding-top: 0;
        }
    }

    .mc__cell-fw .mc__btn {
        display: block;
        margin: 0 auto;
        max-width: rem(250px);
    }
}