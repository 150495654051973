&__heading {
    margin-top: 0;

    &-content {
        @include display(block);
        margin-bottom: rem(calc($comp-pad * 2));
    }
}

&__h2 {
    @include font(fluid(45px, 64px), null, $font-h1, 600);
    color: $brand-primary;

    .mc-weather & {
        color: $brand-secondary;
    }
}

&__h3,
&__h4 {
    width: 100%;
    padding: 0 0 1em;
}

&__h3 {
    border-bottom: 1px solid $white;
    @include font(fluid(14px, 16px), null, $font-h2, 600);
    letter-spacing: .05em;
}

&__h4 {
    padding: .65em 0;
    margin: 0;

    + .mc__num-sm {
        margin-bottom: 0;
    }
}

&-forecast {

    h3.mc__h3 {
        @include font(rem(20px), calc(24/20), $font-paragraph, 400);
        border: 0;
    }

    h4.mc__h3 {
        @include font(rem(16px), calc(19.2/16), $font-paragraph, 400);
    }
}

&-weather {

    .mc__h3,
    .mc__h4 {
        @include font(rem(16px), 1, $font-paragraph, 400);
    }
}

&__h4 {
    @include font(rem(16px), null, $font-h2, 400);
}