.ptc-two-col{
    margin: 16px 0;
}
.ptc-two-col__wrapper{
    @include gutter1();
    @media all and (min-width: $bp-lrg) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-wrap {
        flex-wrap: wrap;
    }

    .weddings & {

        h2 {
            font-family: $font-h2;
        }

        .ptc-two-col__group h2 {
            font-size: fluid(26px, 32px);
            line-height: calc(36/29);
        }
    }

    @media all and (max-width: $bp-med) {
        
        .weddings & {
            padding: 0;
    
            > .ptc-two-col__main,
            > .ptc-two-col__side {
                @include gutter1();
            }
        }
    }

    
}
.ptc-two-col__main{
    flex: 0 1 62%;
}
.ptc-two-col__side{
    flex: 0 1 29%;
}

.ptc-two-col__group {
    margin-top: 50px;

    &.-intro{
        padding-bottom: rem(40px);

        @media all and (min-width: $bp-lrg) {
            padding-left: 8.6%;
        }
        h2 {
            color: $ptc-intro-heading-color;
        }
    }
}
.ptc-two-col__group-heading {
    color: $ptc-two-col-heading-color;
    font-size: rem(32px);
    border-bottom: 1px solid transparentize($color: $brand-primary, $amount: 0.75);
    margin-top: 0;
    margin-bottom: rem(30px);
    font-family: $font-h2;

    .weddings .ptc-two-col__main-fw:last-child & {
        font-size: rem(20px);
        font-family: $font-h4;
        border: 0;
    }
}
.ptc-two-col__content{

    h4{
        margin-bottom: 8px;
        + p{
            margin-top: 8px;
        }
    }
    &-nobreak {
        white-space: nowrap;
    }
}
.ptc-two-col__map-container{
    height: 0;
    padding-bottom: 120%;
}
.ptc-two-col__graphic-list{
    @include list-fix();
    padding-bottom: 40px; 
}
.ptc-two-col__graphic-list-item{
    margin: 16px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-img {
        @include size(rem(100px));
        object-fit: cover;
    }

    svg {
        flex: 0 0 rem(80px);
    }

    &:nth-child(odd) .ptc-two-col__graphic-list-item-img {
        border-radius: 50%;
    }

    &:nth-child(even) .ptc-two-col__graphic-list-item-img {
        border-radius: 25px 0;
    }
}
.ptc-two-col__graphic-list-copy{
    font-size: 16px;
    margin-left: 30px;
}
.ptc-two-col__cta-btn{
    display: block;
    font-family: $font-alert;
    text-align: center;
    padding: 8px 62px;
    border: 1px solid $ptc-two-col-cta-color;
    color: $ptc-two-col-cta-color;
    border-radius: 30px;
    background-color: $white;
    transition: all .35s ease-out;
    &:hover{
        color: $white;
        background-color: $ptc-two-col-cta-color;
        text-decoration: none; 
    }

    .weddings &,
    .wedding-venue-detail & {
        max-width: rem(333px);
        padding: rem(calc($comp-pad / 3)) rem(calc($comp-pad * 3));
        border-radius: 100px;
    }
}
.ptc-two-col__list-heading{
    margin-bottom: 0;
    font-weight: bold;
}
.ptc-two-col__list{
    list-style-type: none;
    margin: 0;
    padding: 0;
}