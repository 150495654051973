&__tab {
    transition: all .35s ease-out;

    &.-active {
        @include display(block);
    }

    &:not(.-active) {
        @include display(none);
    }

    &s {
        width: 100%;
        margin-top: rem($comp-pad);

        .glide__track {
            margin-top: rem($comp-pad);
        }
    }

    > .mc__row > .mc__cell:only-child {
        max-width: none;
    }
}