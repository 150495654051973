.webcam {
    @include display(flex, row wrap, null, center);

    &__widget {
        margin: rem(calc($comp-pad * 4)) 0;

        @media all and (min-width: $bp-lrg) {
            margin: rem(calc($comp-pad * 4)) 0;
        }

        &-splitcamleft {

            .webcam {
                flex-direction: row-reverse;
            }
        }
    }
    &__copy {
        flex: 0 0 100%;
        max-width: 100%;
        @include gutter1;

        @media all and (min-width: $bp-xlrg) {
            flex: 0 0 50%;
            max-width: 50%;

            .webcam__widget-fullwidth & {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        @media all and (min-width: $bp-xlrg) {
            flex: 0 0 rem(800px);
            max-width: rem(800px);

            .webcam__widget-fullwidth & {
                flex: 0 0 65%;
                max-width: 65%;
            }
        }
    }
    &__embed {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: rem(calc($comp-pad * 2));
		@include gutter1;

        @media screen and (min-width: $bp-xlrg) {
            flex: 1;
            max-width: 100%;

            .webcam__widget-fullwidth & {
                flex: 0 0 100%;
                max-width: 100%;
                @include gutter2;
            }
        }
    }
    &__details {
        @include list-fix;

        @media all and (min-width: $bp-med) {
            @include display(flex, row nowrap);

            li {
                @include display(flex, row wrap);
                padding-right: rem($comp-pad);
                border-right: 1px solid $webcam__details-color;
                color: $webcam__details-color;

                &:last-child {
                    padding-left: rem($comp-pad);
                    border: 0;
                }

                strong {
                    margin-right: .5em;
                }
            }
        }
    }
    &__headline {
        font-weight: 700;
    }
    &__delay-load {
        height: 0;
		padding-bottom: 56.25%;
		overflow: hidden;
    }
}