&-parking {
    padding: rem($comp-pad * 2) 0;

    &__wrap {
        @include gutter1;
    }

    &__graph-labels {
        @include list-fix;
        @include display(flex, row nowrap, space-between, flex-start);
        margin-top: rem(calc($comp-pad / 2));

        > li {
            flex: 0 0 auto;
            max-width: 33.33%;
            font-family: $font-h4;
        }
    }

    .mc__btn {
        margin-top: rem($comp-pad);
    }

    @media all and (max-width: $bp-med) {

        + .mc-trails {
            padding-top: 0;
        }
    }
}