.footer{
    background-color: $brand-primary;
    padding: 78px 0 6px;
}
.footer__wrapper{
    @include gutter1();
    @media all and (min-width: $bp-lrg) {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
    }
}
.footer__group1{
    flex: 0 1 27%;
}
.footer__group2{
    flex: 0 1 62%;
}

.footer__loc-info-brand{
    display: inline-block;
    margin-top: $footer-logo-margin;
}
.footer__loc-info-links{
    margin: 40px 0;
}
.footer__loc-info-map-link{
    color: $white;
    display: flex;
    align-items: center;
    &:hover{
        color: $white;
    }
}
.footer__loc-info-map-link-text{
    display: inline-block;
    font-size: 12px;
    margin-left: 6px;
}
.footer__loc-info-address{
    font-size: 12px;
    color: $white;
    margin: 20px 0;
}
.footer__location-address-line{
    display: inline-block;
    margin-right: 12px;
    + .footer__location-address-line{
        margin-right: 0;
        position: relative;
        &:before{
            content: "|";
            display: inline-block;
            margin-right: 12px;
        }
    }
}
.footer__loc-info-phone{
    color: $white;
    font-size: 12px;
    margin: 0;
    a{
        color: $white;
    }
}
.footer__loc-info-social{
    display: flex;
    align-items: flex-start;
    margin: 12px 0;
}
.footer__loc-info-social-link{
    height: 44px;
    display: flex;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    &:first-child{
        padding-left: 0;
    }
}
.footer__newsletter-form{
    display: flex;
    justify-content: space-between;
    position: relative;
    border: 1px solid $white;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.1);
    margin: 40px 0 20px;
    max-width: 400px;
}
.footer__newsletter-form-input{
    padding: 14px 20px;
    
    border: none;
    border-radius: 30px;
    background-color: transparent;
    color: $white;
    flex: 0 1 95%;
    &::placeholder {
        font-size: 14px;
        color: #fff;
        //opacity: .5;
    }
    &:focus{
        outline: 1px dotted $brand-primary--50;
    }
}
.footer__newsletter-form-input-submit{
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 14px 20px;
}

.footer__util-nav{
    @include list-fix();
    padding: 10px 0;
}
.footer__util-nav-item{
    @media all and (min-width: $bp-lrg) {
        display: inline-block;
        margin-right: 20px;
    }
}
.footer__util-nav-item-link{
    color: $white;
    display: block;
    font-size: 12px;
    padding: 8px 0;
    letter-spacing: 0.48px;
    @media all and (min-width: $bp-lrg) {
        font-size: 14px;
    }
    &:link{
        color: $white;
    }
    &:hover{
        color: $white;
        text-decoration: underline;
    }
}

.footer__nav-col-group{
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0 0;
    padding: 30px 0 0;
    
    @media all and (min-width: $bp-lrg) {
        border-top: none;
        flex-wrap: nowrap;
        margin-top: 0;
    }
}
.footer__nav-col{
    flex: 0 1 50%;
    margin-bottom: 40px;
    @media all and (min-width: $bp-lrg) {
        flex: 0 1 25%;
        margin-bottom: 0;
    }
}
.footer__nav-col-title{
    color: $white;
    font-size: 12px;
    letter-spacing: 0.48px;
    font-family: $font-h5;
    //font-weight: 700;
}
.footer__nav-col-list{
    @include list-fix();
}
.footer__nav-col-item{
    margin: 0;
    padding: 0;
}
.footer__nav-col-item-link{
    color: $white;
    display: block;
    font-size: 12px;
    padding: 8px 0;
    letter-spacing: 0.48px;
    &:link{
        color: $white;
    }
    &:hover{
        color: $white;
        text-decoration: underline;
    }
}

.footer__copyright{
    text-align: center;
    padding: 0 0 20px;
    color: $white;
    //opacity: .5;
    font-size: 12px;
    @media all and (min-width: $bp-lrg) {
        padding: 20px;
    }
}