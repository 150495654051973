&__callout {
    @include display(flex, row wrap, null, center);
    padding: rem(calc($comp-pad * 1.5));
    border-radius: 25px 0 25px 0;
    border: 1px solid $brand-primary--25;

    @media all and (min-width: $bp-med) {
        padding: rem(calc($comp-pad * 2));
    }

    &-keys {
        @include display(block);
        margin: 0 auto;
        padding: rem(calc($comp-pad / 2)) rem($comp-pad);
        border-radius: 25px;
        background-color: $white;

        @media all and (min-width: $bp-med) {
            padding: rem($comp-pad) rem(calc($comp-pad * 2));
        }
    }

    .mc-weather & {
        @include gutter2;
        padding-top: rem(calc($comp-pad * 2));
        padding-bottom: rem(calc($comp-pad * 2));
        @media all and (min-width: $bp-med) {
            padding-top: rem(calc($comp-pad * 4));
            padding-bottom: rem(calc($comp-pad * 4));
        }
        border-radius: 0px;
        background: $white;
        border: 0;

        p {
            margin: 0 0 rem($comp-pad);
            color: $brand-primary;

            &:last-child {
                margin-bottom: 0;
            }

            &.mc__timestamp {
                letter-spacing: 0.05em;
                color: $brand-primary--50;
            }
        }

		.forecast-text {
			color: $brand-primary;
		}
    }
}