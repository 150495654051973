.faq{
    position: relative;
}
.faq__intro{
    margin: 1.5rem 0;
    @media all and (min-width: $bp-lrg) {
        margin: 3rem 0;
    }
}
.faq__intro-content{
    @media all and (min-width: $bp-lrg) {
        max-width: 50%;;
    }
}
.faq__intro-wrapper{
    @include gutter1();
}
.faq__item{
    margin: 1.5rem 0;
    @media all and (min-width: $bp-lrg) {
        margin: 3rem 0;
    }
}
.faq__intro{
    p{
        font-size: 0.875rem;
    }
}
.faq__item-q{
    margin-bottom: 1.5rem;
}
.faq__item-answer{
    font-size: 0.875rem;
    p, li{
        font-size: 0.875rem;
    }
}