.achievement-info{
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
}
.achievement-info__wrapper{
    @include gutter1();
    padding-right: 0;
    @media all and (min-width: $bp-lrg) {
        padding-right: 0;
    }
}
.achievement-info__carousel{
    &.swiper{
        //overflow: visible;
    }
}
.achievement-info__carousel-ctrls{
    margin: 20px 0;
    display: flex;
    align-items: center;
}
.achievement-info__ctrls{
    margin-left: 24px;
}
.achievement-info__ctrl-btn{
    border: 1px solid $card-secondary-color;
    background-color: #fff;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 25px;
    margin-right: 8px;
    svg path{
        fill: $card-secondary-color;
    }
    &.-next{
        background-color: $card-secondary-color;
        svg path{
            fill: #fff;
        }
    }
}
.achievement-info__cta-link{
    border: 1px solid $card-secondary-color;
    border-radius: 25px;
    padding: 12px 16px;
    color: $card-secondary-color;
    font-size: 14px;
    font-family: $font-alert;
    &:hover{
        color: #fff;
        background-color: $card-secondary-color;
        text-decoration: none;
    }
}
.achievement-info__carousel-slide{
    @include list-fix();
    display: flex;
    align-items: stretch;
}
.achievement-info__carousel-item{
    margin: 0;
    padding: 30px 0 0 0;
    position: relative;
    
    max-width: 180px;
    @media all and (min-width: $bp-lrg) {
        max-width: 270px;
    }
    &.swiper-slide{
        height: auto;
    }
}
.achievement-info__item-category{
    font-family: $font-alert;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
}
.achievement-info__item-container{
    
    border-radius: 25px 0;
    text-align: center;
    padding: 25px;
    height: 100%;
    .achievement-info__carousel-item.-blue &{
        background-color: transparentize($brand-primary, .31);
    }
    .achievement-info__carousel-item.-gold &{
        background-color: $brand-secondary;
    }
}
.achievement-info__heading{
    margin-bottom: 0;
}
.achievement-info__item-date{
    color: $achievement-info-label-alt-color;
    font-family: $font-alert;
    font-size: 12px;
    margin-bottom: 34px;
    .achievement-info__carousel-item.-blue &{
        color: #fff;
    }
}
.achievement-info__item-img{
    display: block;
    margin: 0 auto;
}
.achievement-info__item-title{
    font-size: 20px;
    margin-top: 36px;
    color: $achievement-info-label-alt-color;
    .achievement-info__carousel-item.-blue &{
        color: #fff;
    }
}
.achievement-info__item-desc{
    color: $achievement-info-label-alt-color;
    font-size: 14px;
    margin-bottom: 0;
    .achievement-info__carousel-item.-blue &{
        color: #fff;
    }
}