&-uphill {
    padding: rem($comp-pad * 2) 0;

    &__wrap {
        @include gutter1;
    }

    &__callout {
        margin: 0 auto;

        > * {
            @include display(inline-block);
            vertical-align: middle;

            &:first-child {
                margin-right: .5em;
            }
        }
    }
    
    .mc__heading {
        margin-bottom: 0;
        color: $brand-primary;

        @media all and (max-width: $bp-med) {
            width: 100%;
        }
    }

    .mc_p {
        width: 100%;
        margin: rem($comp-pad) 0 0;
    }

    .mc__nav-tabs {
        flex: 0 0 100%;
        max-width: 100%;

        @media all and (max-width: $bp-lrg) {
            order: -1;
            margin-bottom: rem($comp-pad);
        }
        @media all and (min-width: $bp-lrg) {
            flex: 1 0 50%;

            + .mc_p {
                width: 50%;
            }
        }
        @media all and (min-width: $bp-xlrg) {
            flex: 1 0 65%;
        }
    }

    
}