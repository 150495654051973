.card{
    display: block;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 rem($comp-pad) rem(calc($comp-pad * 2));
    
    @media all and (max-width: $bp-med) {
        .custom-card.-alt-offset & {
            padding: 0 0 rem(calc($comp-pad * 2));
        }
    }

    @media all and (min-width: $bp-med) {
        flex: 0 0 50%;
        max-width: 50%;

        .weddings & {
            width: 100%;
        }
    }
    @media all and (min-width: $bp-lrg) {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);

        .custom-card.-alt-offset & {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &.-vert{
        flex: 0 0 rem(400px);
        max-width: 80%;
        margin: 0;
        padding: 0;

        @media all and (min-width: $bp-med) {
            min-width: rem(350px);
            max-width: rem(400px);
        }
    }
    &.-vert:nth-child(even){

        .custom-card.-alt-height & {
            padding: 0 rem(24px) rem(24px);
            margin-top: rem(40px);
        }
    }
    .custom-card.-alt-offset &{

        &:nth-child(odd) {
            padding-right: 0;
            margin-right: 0;
        }
        &:nth-child(even) {
            padding-left: 0;
            margin-left: 0;

            .card__content {
                padding-left: rem(calc($comp-pad * 2.5));
            }
        }
        @media all and (min-width: $bp-med) {
            margin: 0 0 50px 0;
        }
        @media all and (min-width: $bp-lrg) {
            flex: 0 0 48%;
            max-width: 48%;
            margin: 0 2% 50px;
        }
    }
    .custom-card.-alt-offset &:nth-child(odd){
        @media all and (min-width: $bp-lrg) {
            //transform: translateY(-30%);
        }
    }
    .custom-card.-alt-offset &:nth-child(even){
        @media all and (min-width: $bp-lrg) {
            margin-top: 15%;
        }
    }
}
.card__link{
    text-decoration: none;
    white-space:normal;
    &:hover{
        text-decoration: none;
    }
}
.card__nolink{
	text-decoration: none;
    white-space:normal;
}
.card__media{
    position: relative;
    .custom-card.-alt-offset &{
        padding-right: 36px;
        position: relative;
    }
    .custom-card.-alt-offset .card:nth-child(even) &{
        @media all and (max-width: $bp-lrg) {
            padding-right: 0px;
            padding-left: 36px;
        }
    }
}
.card__media-caption{
    position: absolute;
    right: 6px;
    top: 0;
    .custom-card.-alt-offset .card:nth-child(even) &{
        @media all and (max-width: $bp-lrg) {
            right: auto;
            left: 6px;
        }
    }
}
.card__media-img{
    display: block;
    width: 100%;
    transition: border-radius .35s;
    overflow: hidden;
    position: relative;
    .card__link:hover &{
        border-radius: 0 25px;
    }
}
.card__media-caption-text{
    font-size: 14px;
    font-family: $font-alert;
    position: absolute;
    top: 36px;
    left: 22px;
    transform: rotate(90deg);
    transform-origin: top left;
    white-space: nowrap;
}
.card__media-cal{
    background-color: $card-secondary-color;
    border-radius: 0px 0px 25px 0px;
    color: #fff;
    padding: 14px;
    position: absolute;
    left: 0;
    top: 0;

    .custom-card.-alt-offset .card:nth-child(even) &{
        @media all and (max-width: $bp-lrg) {
            left: rem(36px);
        }
    }
}
.card__media-cal-meta{
    display: inline-block;
    font-family: $font-alert;
    font-size: 10px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #fff;
}
.card__media-cal-line{
    font-family: $font-h3;
    font-size: 32px;
    line-height: 1;
}
.card__content{
    margin-top: 25px;

    .custom-card.-alt-offset &{
        padding: 0 rem($comp-pad);
        @media all and (min-width: $bp-lrg) {
            padding: 0 rem(calc($comp-pad * 2));
            margin-top: rem(calc($comp-pad * 2));
        }
    }
}
.card__heading{
    font-size: 20px;
    .custom-card.-alt-big-text &{
        font-size: 45px;
        @media all and (min-width: $bp-lrg) {
            font-size: 64px;
        }
    }
}
.card__meta-primary{
    color: $card-secondary-color;
    font-family: $font-alert;
    font-size: 14px;
}
.card__copy{
    font-size: 14px;
}
.card__meta-secondary{
    font-size: 12px;
    color: $brand-primary--50;
}
.card__faux-cta-btn{
    @extend .cta-btn;
    //padding: rem(calc($comp-pad / 4)) 0;
    font-size: 14px;
    color: $card-secondary-color;
    border: 1px solid $card-secondary-color;
    .card__link:hover &{
        color: #fff;
        background-color: $card-secondary-color;
    }

}

.card {

    &__fw {
        @include display(flex, row nowrap, null, center);
        flex: 0 0 100%;
        min-width: 100%;
        margin: 0 auto;
        padding: rem(calc($comp-pad * 2)) 0;
        border-bottom: 1px solid $brand-primary--50;

        @media all and (min-width: $bp-med) {
            padding: rem($comp-pad) 0;
        }
        
        &:last-child {
            border: 0;
        }

        .card__content {
            flex: 0 0 100%;
            margin-top: 0;

            @media all and (min-width: $bp-med) {
                flex: 0 0 50%;

                &:first-child {
                    padding: rem($comp-pad) rem($comp-pad) rem($comp-pad) 0;
                }
                
                &:last-child {
                    padding: rem($comp-pad) 0 rem($comp-pad) rem($comp-pad);
                }
            }
        }

        .card__heading {
            display: inline;
            border-bottom: 1px solid transparent;
            @include font(rem(32px));
            transition: all .2s ease-out;
        }

        .card__faux-cta-btn {
            display: inline;
            position: relative;
            vertical-align: middle;
            border-radius: 0px;
            border: 0;
            border-bottom: 1px solid transparent;
            padding: rem(15px) 0 0;
            background-color: transparent;
            transition: all .2s ease-out;

            &:after {
                vertical-align: middle;
                @include triangle(right, $card__fw-color, .35em);
                display: inline-block;
                @include position(absolute, null, (top, left), (calc(50% + rem(10px)), 100%), translateY(-50%));
                margin-left: 1em;
                margin-top: -0.2em;
            }
        }
        .card__link {
            @include display(flex, row wrap, null, center);
            width: 100%;

            @include hover {
                
                .card__faux-cta-btn {
                    
                    @include color(transparent, $card__fw-color, $card__fw-color);
                }
            }
        }
		.card__nolink {
			@include display(flex, row wrap, null, center);
            width: 100%;
		}
        
        &-list.custom-card__list {
            margin: 0;
            padding: rem($comp-pad) 0;
        }
        .custom-card.-reverse & {
            max-width: none;
            padding: rem($comp-pad) 0;
        }
    }
}

.custom-card {

    &.-cool,
    &.-warm,
    &.-single-color,
    &.-reverse {
        margin: 0;
    }

    &.-cool {
        background: $brand-primary--5;

        .card__link {
            @include hover {
                
                .card__heading {
                    border-color: $brand-primary;
                }
            }
        }
        .card__meta-primary {
            color: $custom-card__meta-color;
        }
    }

    &.-warm {
        background: $brand-secondary--5;

        .card__link {
            @include hover {
                .card__heading {
                    border-color: $brand-primary;
                }
            }
        }
    }

    &.-single-color {

        .card__heading,
        .card__copy,
        .card__meta-primary,
        .card__faux-cta-btn {
            color: $card__fw-color;
        }
        .card__meta-secondary {
            color: rgba($card__fw-color, .6);
        }
        .card__link {
            @include hover {
                .card__heading {
                    border-color: $card__fw-color;
                }
            }
        }
    }

    &.-reverse {
        padding: rem(calc($comp-pad * 4)) 0;
        background-color: $brand-primary;

        .custom-card__list {
            //margin: 0;
            //padding: 0;
        }

        .custom-card__heading {
            margin: 0;
            padding: 0 0 rem($comp-pad);
            @include font(rem(20px));
            font-weight: 700;
            color: $card__reverse-heading-color;
            border-bottom: 1px solid $brand-primary--50;
        }

        .card__heading {
            display: inline;
            flex: 0 0 auto;
            max-width: calc(100% - #{rem(40px)});
            position: relative;
            margin: 0;
            //padding: rem(calc($comp-pad / 5)) 0;
            border-bottom: 1px solid transparent;
            color: $white;
        }
        .card__link {
            @include display(flex, row nowrap, null, center);
            padding: rem(calc($comp-pad * 1.5)) 0;

            @media all and (min-width: $bp-lrg) {

                @include hover {

                    .card__heading {
                        border-color: $white;
                    }
                }
            }

            &::after {
                @include triangle(right, $brand-secondary, rem(6px));
                flex: 0 0 auto;
                max-width: rem(20px);
                margin-left: rem(20px);
                transition: all .2s ease-out;
            }
        }
		.card__nolink {
			@include display(flex, row nowrap, null, center);
            padding: rem(calc($comp-pad * 1.5)) 0;
		}
        .card__fw {
            padding: 0;

            .card__link,
			.card__nolink {
                padding: rem(calc($comp-pad * 2)) 0;
            }
        }
    }
}