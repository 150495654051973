$icomoon-font-family: "mountain-conditions" !default;
$icomoon-font-path: "../fonts/MountainConditionsIcons" !default;

$icon-open: "\e900";
$icon-closed: "\e901";
$icon-hold: "\e902";
$icon-expected: "\e903";
$icon-delayed: "\e904";
$icon-easy: "\e905";
$icon-moderate: "\e906";
$icon-difficult: "\e907";
$icon-most-difficult: "\e908";
$icon-mixed-use: "\e909";
$icon-hiking: "\e90b";
$icon-biking: "\e90c";
$icon-gondola: "\e90d";
$icon-chair: "\e90e";
$icon-magic-carpet: "\e90f";
$icon-snow: "\e910";
$icon-groomed: "\e911";
$icon-terrain-park: "\e912";