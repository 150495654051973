.three-img{
    padding: 28px 0;
    position: relative;
    @media all and (min-width: $bp-lrg) {
        padding: 16.4% 0;
    }
    &.-blue{
        background-color: $three-img-blue;
    }
    &.-lt-blue{
        background-color: $three-img-lt-blue;
    }
    
}
.three-img__wrapper{
    @include gutter2();
    @media all and (min-width: $bp-lrg) {
        display: flex;
        //margin: 0 -24px;
    }
}
.three-img__media{
    margin: 22px auto;
    @media all and (min-width: $bp-lrg) {
        margin: 0;
        flex: 0 1 33%;
    } 
    &:nth-child(2){
        @media all and (min-width: $bp-lrg) {
            margin: 0 45px;
        }
    }
}
.three-img__img{
    display: block;
    margin: 0 auto;
}