.bar {

    &__nav {
        width: calc(100% - 48px);
        background-color: $brand-secondary;
        border-radius: 30px;
        position: sticky;
        bottom: 0px;
        left: 24px;
        transform: translate(0, -50%);
        z-index: 1;
        @media all and (min-width: $bp-lrg) {
            left: 10%;
            width: 80%;
        }

        &-list{
            @include list-fix();
            display: flex;
            justify-content: space-between;
        }

        &-item{
            margin: 0;
            padding: 0;
            flex: 0 1 100%;

            &.-active + .bar__nav-item .bar__nav-link:before {
                height: 0%;
            }
        }

        &-link{
            color: $hero-menu-text-color;
            @include display(flex, row nowrap, center, center);
            height: 100%;
            padding: 20px 10px;
            position: relative;
            font-size: 12px;
            text-align: center;
            font-family: $font-alert;
            @media all and (min-width: $bp-lrg) {
                font-size: 14px;
            }
            &:focus {
                outline: none;
            }
            &:hover,
            .bar__nav-item.-active & {
                color: $hero-menu-text-color;
                text-decoration: none;
            }
            &:before{
                content: "";
                background-color: $hero-menu-text-color;
                display: block;
                width: 1px;
                height: 60%;
                top: 50%;
                left: 0;
                position: absolute;
                transform: translateY(-50%);
                transition: height .35s;
            }
            .bar__nav-item:hover + .bar__nav-item &:before {
                height: 0%;
            }
            .bar__nav-item:first-child &:before{
                display: none;
            }
            &:hover:before,
            .bar__nav-item.-active &:before {
                height: 0%;
            }
            &:after{
                background-color: $white;
                content: "";
                display: block;
                width: 0%;
                height: 0%;
                top: 50%;
                left: 50%;
                position: absolute;
                transition: all .35s;
                border-radius: 30px;
                opacity: 0;
                z-index: -1;
            }
            &:hover:after,
            .bar__nav-item.-active &:after {
                width: 100%;
                height: 100%;
                left: 0%;
                top: 0%;
                opacity: $hero-menu-link-opacity;
            }
        }

        .booking & {
            width: 100%;
            position: relative;
            transform: translateY(0);
            margin-bottom: 1em;
            left: 0;
        }
    }
}