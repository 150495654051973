.amenities{
    margin: 30px 0;
    @media all and (min-width: $bp-lrg) {
        margin: 60px 0;
    }
}
.amenities__wrapper{
    @include gutter1();
}
.amenities__heading{
    color: $brand-secondary;
    font-size: 32px;
}
.amenities__group{
    overflow: hidden;
    background-color: $brand-secondary--5;
    padding: 50px 30px;
    @media all and (min-width: $bp-lrg) {
        padding: 50px 75px;
    }
}
.amenities__group-wrapper{
    overflow: hidden;
    max-height: 430px;
    transition: max-height .35s;
    @media all and (min-width: $bp-lrg) {
        max-height: auto;;
    }
    .amenities.-show-all &{
        max-height: 1000px;
    }
}
.amenities__list{
    @include list-fix();
    display: grid;
    grid-template-columns:1 1fr;
    row-gap: 20px;
    column-gap: 40px;
    @media all and (min-width: $bp-lrg) {
        grid-template-columns: repeat(3, 1fr);
    }
}
.amenities__list-item{
    //margin: 10px 0;
}
.amenities__mobile-toggle{
    display: block;
    font-family: $font-alert;
    text-align: center;
    padding: 8px 62px;
    border: 1px solid $ptc-two-col-cta-color;
    color: $ptc-two-col-cta-color;
    border-radius: 30px;
    background-color: transparent;
    margin-top: 20px;
    transition: all .35s ease-out;
    &:hover{
        color: $white;
        background-color: $ptc-two-col-cta-color;
        text-decoration: none; 
    }
    .amenities.-show-all &{
        display: none;
    }
    @media all and (min-width: $bp-lrg) {
        display: none;
    }
}