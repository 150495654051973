.cta-btn {
    color: $brand-primary;
    display: inline-block;
    padding: 15px 25px;
	position: relative;
    background: $white;
	border: 1px solid $brand-primary;
	border-radius: 30px;
	-webkit-appearance: none;
    font-family: $font-alert;
    line-height: 1;
    transition: color .35s, background-color .35s;
    &:hover{
        background-color: $brand-primary;
        text-decoration: none;;
        color: $white;
    }
    .cta-btn + &,
    .cta-btn-secondary + &{
        margin-left: 10px;
        @media all and (max-width: 500px) {
            margin-left: 0px;
            margin-top: 16px;
            
        }
    }
    @media all and (max-width: 500px) {
        display: block;
        text-align: center;
    }
}
.cta-btn-secondary{
    color: $brand-primary;
    display: inline-block;
    font-family: $font-alert;
    padding: 15px 25px 15px 0;
	position: relative;
	border: 1px solid transparent;
	border-radius: 30px;
	-webkit-appearance: none;
    line-height: 1;
    .cta-btn + &{
        margin-left: 25px;
    }
    .cta-btn-secondary + &{
        margin-left: 25px;
    }
    &:after{
        content: "";
        display: block;
        width: 0; 
        height: 0; 
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid $brand-primary;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:before{
        content: '';
        background-color: $brand-primary;
        position: absolute;
        bottom: 15px;
        left: 0;
        display: block;
        height: 1px;
        width: 0;
        transition: width .35s;
    }
    &:hover{
        text-decoration: none;
    }
    &:focus{
        outline: none;
    }
    &:hover:before,
    &:focus:before{
        width: calc(100% - 25px);
    }
}