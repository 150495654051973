.account-info{
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
}
.account-info__wrapper{
    @include gutter1();
    @media all and (max-width: $bp-lrg) {
        padding: 0;
    }
}
.account-info__block{
    background-color: $brand-primary--5;
    padding: 60px 26px;
    @media all and (min-width: $bp-lrg) {
        padding: 80px 74px;
    }
}
.account-summary{
    padding-bottom: 60px;
}
.account-summary__heading{
    @extend .h3;
    margin-top: 0;
}
.account-summary__group{
    @media all and (min-width: $bp-lrg) {
        display: flex;
        flex-direction: row-reverse;
    }
}
.account-summary__media{
    flex: 0 1 33%;
}
.account-summary__media-img{
    border-radius: 150px;
}
.account-summary__cols{
    flex: 0 1 66%;
    @media all and (min-width: $bp-lrg) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.account-summary__col{
    flex: 0 1 48%;
}
.account-summary__status{
    display: flex;
    margin-bottom: 30px;
}
.account-sumary__status-ind{
    display: flex;
    align-items: center;
    font-family: $font-alert;
    color: $brand-secondary;
   
}
.account-sumary__status-alert{
    display: flex;
    align-items: center;
    font-family: $font-alert;
    color: $alert-green;
    margin-left: 16px;
}
.account-sumary__status-text{
    line-height: 1;
    display: inline-block;
    margin-left: 8px;
    margin-top: 3px;
}
.account-summary__cta-link{
    @include display(inline-flex, row nowrap, null, center);
    border: 0;
    padding-left: 0;
    background-color: transparent;
    transition: all .2s ease-out;

    &::after {
        @include triangle(right, $card__fw-color, .35em);
        margin-left: 1em;
        transition: all .2s ease-out;
    }
    &:hover:after {
        margin-left: 2em;
    }
}
.pass-benefits__cta-btn{
    border: 1px solid $account-cta-color;
    color: $account-cta-color;
    font-size: 14px;
    font-family: $font-alert;
    padding: 12px 16px;
    border-radius: 25px;
    transition: all .35s;
    &:hover{
        text-decoration: none;
        background-color: $account-cta-color;
        color: #fff;
    }
}
.pass-benefits{
    border-top: 1px solid $brand-primary--25;
    padding-top: 60px;
}
.pass-benefits__heading{
    @extend .h3;
}
.pass-benefits__desc{
    margin-bottom: 30px;
}