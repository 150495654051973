&__row {
    @include display(flex, row wrap, flex-start, flex-start);
    width: calc(100% + calc($comp-pad * 2));
    @include list-fix;
    margin: 0 calc($comp-pad * -1);

    &.ha-c {
        justify-content: center;
    }

    &.va-c {
        align-items: center;
    }

    &.ha-b {
        justify-content: space-between;
    }

    &-stack {
        flex-flow: column nowrap;
        align-items: stretch;

        @media all and (min-width: $bp-med) {
            &-md {
                flex-flow: column nowrap;
                align-items: stretch;
            }
        }
        @media all and (min-width: $bp-lrg) {
            &-lg {
                flex-flow: column nowrap;
                align-items: stretch;
            }
        }
    }
}