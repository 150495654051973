.two-img-inset{
    position: relative;
    &.-padded{
        @include gutter2();
        margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
    }
    
}

.two-img-inset__img1{
    display: block;
    width: 100%;
}

.two-img-inset__img2{
    position: absolute;
    bottom: 50px;
    right: 50px;
    width: 42%;
    @media all and (min-width: $bp-lrg) {
        width: 30%;
        right: 10.4%;
        bottom: 7.5%;
    }
    .two-img-inset.-img-bottom-left &{
        right: auto;
        left: 50px;
        @media all and (min-width: $bp-lrg) {
            left: 10.4%;
            bottom: 7.5%;
        }
    }
    .two-img-inset.-img-top-left &{
        bottom: auto;
        right: auto;
        left: 50px;
        top: 50px;
        @media all and (min-width: $bp-lrg) {
            left: 10.4%;
            top: 7.5%;
        }
    }
    .two-img-inset.-img-top-right &{
        bottom: auto;
        top: 50px;
        @media all and (min-width: $bp-lrg) {
            top: 7.5%;
        }
    }
}

.two-img-inset__caption{
    background-color: $white;
    color: $brand-primary;
    font-size: 12px;
    padding: 12px 26px;
    
    width: 300px;
    position: absolute;
    margin: 0 40px;
    top: 50px;
    right: calc(50% - 190px);
    @media all and (min-width: 600px) {
        margin: 0;
        right: auto;
        top: 4%;
        left: 4.8%;
    }
    .two-img-inset.-caption-top-right &{
        @media all and (min-width: 600px) {        
            left: auto;
            top: 4%;
            right: 4.8%;
        }
    }
    .two-img-inset.-caption-bottom-right &{
        top: auto;
        bottom: 50px;
        @media all and (min-width: 600px) {        
            left: auto;
            bottom: 4%;
            right: 4.8%;
        }
    }
    .two-img-inset.-caption-bottom-left &{
        top: auto;
        bottom: 50px;
        @media all and (min-width: 600px) {        
            bottom: 4%;
            right: 4.8%;
        }
    }
}