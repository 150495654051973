// Base Sizes

$comp-pad: 24px;
$comp-pad-med: 10%;
$comp-pad-lrg: 5.2%;



// Breakpoints
$bp-sml : 28em; // 768
$bp-med : 46.8em; // 768
$bp-lrg : 66em; //1056
$bp-xlrg : 90em; //1056


// Screen sizes for font calculations
$min-screensize: 375px;
$max-screensize: 1900px;
