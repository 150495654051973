&__key {
    @include display(flex, row nowrap, null, center);
    flex: 0 0 100%;
    max-width: 100%;
    padding: rem(calc($comp-pad / 2));
    white-space: nowrap;

    &s {
        @include list-fix;
        @include display(flex, row wrap);
    }

    svg {
        flex: 0 0 auto;
        margin-right: 1em;

        .mountain-report-dropdown & {
            margin-right: 0;
        }

        + p {
            flex: 0 0 auto;
            width: 1em;
            margin: 0 .85em;
        }
    }
    
    .mc__h4 {
        width: auto;
    }
}