.feature-media{
    margin: $comp-pad 0;

    &.glide--swipeable{
        cursor: default;
    }
    &.-show-multiple{
        overflow:hidden;
    }

    .weddings__result & {
        @include position;
        
        &__ctrls {
            @include position(absolute, 1, (top, left), ($comp-pad, $comp-pad));
            margin: 0;
            padding: 0;

            @media all and (min-width: $bp-med) {
                left: calc($comp-pad * 2);
            }
        }

        &__pagination-bullet {

            &s {
                @include position(absolute, 2, (bottom, left), (calc($comp-pad / 2), 50%), translateY(-50%));
            }
        }

        &__lightbox-trigger {

            &s {
                @include position(absolute, 2, (top, right), ($comp-pad, $comp-pad));

                @media all and (max-width: $bp-lrg) {
                    display: none;
                }
            }
        }
    }
}

.feature-media__ctrls{
    @include gutter1();
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    @media all and (min-width: $bp-lrg) {
        margin-bottom: 50px;
    }
}
.feature-media__content{
    @include gutter2();
    position: relative;

    .weddings__result & {
        padding: 0;

        @media all and (min-width: $bp-med) {
            padding-left: $comp-pad;
        }
    }
    .feature-media.-show-multiple &{
        @media all and (min-width: $bp-lrg) {
            max-width: 60vw;
            padding-right: 0;
        }
    }
}
.feature-media__play-btn{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    @media all and (min-width: $bp-lrg) {
        width: 168px;
    }
}
.feature-media__img{
    display: block;
    width: 100%;
}
.feature-media__ctrl-btn{
    display: inline-block;
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 25px;
    border: 1px solid $feature-img-slider-btn-color;
    + .feature-media__ctrl-btn{
        margin-left: 16px;
    }
    &.-prev{
        @include color($white, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color);
    }
    &.-next{
        background-color: $feature-img-slider-btn-color;
    }
    &.glide__arrow--disabled{
        cursor: not-allowed;
    }

    .weddings__result-media & {

        &.-prev {
            @include color(transparent, $white, $white, $white, $white, $white);
        }

        &.-next {
            @include color($white, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color, $weddings__filter-ctrl-color);
        }
    }
}
.feature-media__ctrl-count{
    display: inline-block;
    margin-left: 16px;
    color: $feature-img-slider-btn-color;
    font-size: 20px;
    font-family: $font-h2;

    .weddings & {
        font-family: $font-h1;
    }
}
.feature-media__ctrl-count-current,
.feature-media__ctrl-count-total{
    display: inline-block;
    padding: 8px;
}
.feature-media__slider{
    .feature-media.glide--swipeable &{
        cursor: grab;
    }
    .feature-media.-show-multiple &.glide__track{
        overflow: visible;
    }
}
.feature-media__figure{
    transition: opacity .35s, transform .35s;

    .glide__slide &{
        opacity: 0;
        //transform: scale(.8);
    }
    .glide__slide.glide__slide--active &{
        opacity: 1;
        //transform: scale(1);
    }
    .glide__slide.glide__slide--active.-leaving &{
        opacity: 0;
        //transform: scale(.8);
    }
    .feature-media.-show-multiple &{
        opacity: 1;
        transition: none;
    }

    .weddings__result & {
        display: block;
        @include size(100%);
        position: relative;
        background: $brand-primary--5;

        img {
            @include position(absolute, null, (top, left), (50%, 50%), translate(-50%, -50%));
            width: auto;
            max-width: none;
            max-height: 100%;
        }
    }
}