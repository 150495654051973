.accordion{
    margin: 0 0 rem(calc($comp-pad * 2)) 0;
    padding: 0;
    @media all and (min-width: $bp-lrg) {
        margin: 0 0 40px 0;
    }
}
.accordion__wrapper{
    @include gutter1();

    .weddings &,
    .mc-lifts & {
        padding: 0;
    }
}
.ptc-two-col .accordion__wrapper {
    @include no-gutter();
}

.accordion__header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        width: 100%;
        margin: 0;
        color: $mc-lifts__h3-color;

        a {
            color: $mc-lifts__h3-color;

            @include hover {
                text-decoration: none;
            }
        }
    }
}
.accordion__heading{
    color: $acord-heading-color;
    font-size: 32px;
}
.accordion__toggle{
    display: block;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: rem(15px) 0;
    border-bottom: 1px solid $brand-primary--25;

    &.-active {
        border: 0;
    }
}

.accordion__toggle-indicator{
    display: inline-block;
    width: 16px;
    height: 2px;
    background-color: $acord-heading-color;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all .35s;
    &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        transition: all .35s;
        background-color: $acord-heading-color;
    }
    .accordion__toggle.-active &:after{
        transform: rotate(0deg);
    }
    .accordion.-open &:after{
        transform: rotate(0deg);
    }
}
.accordion__content{
    overflow:hidden;
    height: 0;

    .accordion.-open &{
        height: auto;
    }

    @media all and (max-width: $bp-med) {
        margin: 0 rem(calc($comp-pad * -1));
    }
}
.accordion__content-container{
    padding: rem($comp-pad);
    @media all and (min-width: $bp-lrg) {
        padding: rem($comp-pad) calc(rem($comp-pad) * 2);
    }

    .accordion__group & {
        @media all and (min-width: $bp-sml) {
            padding: calc(rem($comp-pad) * 2);
        }
        p, ul, ol {
            &:first-child {
                margin-top: 0;
            }
        }
        p, ul, ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .mc-lifts &,
    .mc-trails & {
        padding-bottom: calc($comp-pad * 2);
        background: $brand-primary--5;
    }
}

.accordion__grid{
    display: grid;
}
