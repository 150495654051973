.custom-card{
    position: relative;
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
    &.-slider{
        overflow: hidden;
    }

    + .mc-weather {
        margin-top: rem(-50px);
    }
    + .accordion {
        margin-top: rem(80px);
    }
}

.custom-card__wrapper{
    @include gutter1();
    .custom-card.-alt-offset &{
        @media all and (max-width: $bp-lrg) {
            padding: 0;
        }
    }

    .custom-card.-slider & {
        padding-right: 0;

        .custom-card__slider {
            padding-right: 30px;
        }
    }
}
.custom-card__hgroup{
    margin-bottom: 70px;
    @media all and (max-width: $bp-lrg) {
        padding: 0 $comp-pad;
    }
    @media all and (min-width: $bp-lrg) {
        max-width: 45%;

        .custom-card.-alt-offset & {
            transform: translateY(50%);
            margin: 0;
        }
    }
}
.custom-card__list{
    @include list-fix();
    display: flex;
    justify-content: flex-start;
    margin: 0 rem(calc($comp-pad * -1));
    flex-wrap: wrap;

    .custom-card.-alt-offset &{
        margin: 0;

        @media all and (min-width: $bp-lrg) {
            flex-direction: row-reverse;
        }
    }
    .custom-card {

        &.-cool,
        &.-warm,
        &.-single-color {
            margin: 0;
            padding: 0;
        }
    }
}
.custom-card__heading{
    margin: 0 0 rem(calc($comp-pad * 2));
    color: $card-secondary-color;
    font-size: 26px;
    font-family: $font-h2;
    @media all and (min-width: $bp-lrg) {
        font-size: 32px;
    }
    .custom-card.-alt-offset &{
        color: $brand-primary;
        font-size: 45px;
        font-family: $font-h1;
        font-weight: 700;
        margin-bottom: 0;
        @media all and (min-width: $bp-lrg) {
            font-size: 64px;
        }
        
    }
}
.custom-card__subheading{
    color: $card-secondary-color;
    font-size: 26px;
    font-family: $font-h2;
    margin-top: 1rem;
    @media all and (min-width: $bp-lrg) {
        font-size: 32px;
    }
}
.custom-card__slider{
    &.glide__track{
        overflow: visible;
    }
}
.custom-card__slider-list{
    @include list-fix();
}
.custom-card__ctrl{
    margin: 24px 0 0;
}
.custom-card__ctrl-progress{
    width: 100%;
    height: 2px;
    background-color: $brand-primary--5;
    position: relative;
}
.custom-card__ctrl-progress-indicator{
    position: absolute;
    height: 100%;
    background-color: $brand-primary;
    left: 0;
    top: 0;
    width: 40px;
}
.custom-card__ctrl-btn-group{
    margin: 20px 0;
    display: flex;
    align-items: center;
}
.custom-card__ctrl-btn{
    border: 1px solid $card-secondary-color;
    background-color: #fff;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 25px;
    margin-right: 16px;
    svg path{
        fill: $card-secondary-color;
    }
    &.-next{
        background-color: $card-secondary-color;
        svg path{
            fill: #fff;
        }
    }
}
.custom-card__cta-link{
    border: 1px solid $card-secondary-color;
    border-radius: 25px;
    padding: 12px 16px;
    color: $card-secondary-color;
    font-size: 14px;
    font-family: $font-alert;
    &:hover{
        color: #fff;
        background-color: $card-secondary-color;
        text-decoration: none;
    }
}