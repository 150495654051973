$menu-break : $bp-lrg;
$menu-btn-toggle : 48em;
$btn-z-index: 10;
$header-height: rem(75px);

.header{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 5px 0 15px;
    position: fixed;
    top: 0;
    width: 100%;    
    height: $header-height;
    transition: transform .5s, background-color .5s;
    z-index: 1000;
    &.-has-hero,
    &.-search-open,
    &.-mountain-report-open,
    &.-has-hero.-scrolled,
    &.-has-hero.-scrolled.-search-open,
    &.-has-hero.-scrolled.-mountain-report-open{
        svg{
            path:not(.sun){
                fill: $white;
                //stroke: $white;
                &.sun{
                    fill: auto;
                }
            }
        }
    }
    &.-scrolled{
        background-color: $white;
        //transition: transform .5s .8s, background-color .5s;
        &.-scrollup{
            //transition: transform .5s, background-color .5s;
            transform: translateY(0%);
        }
    }
    &.-has-hero.-scrolled{
        svg{
            path:not(.sun){
                fill: $brand-primary;
                //stroke: $brand-primary;
            }
        }
    }
    &.-has-hero.-main-nav-open,
    &.-main-nav-open{
        @media all and (max-width: $menu-break) {
            background-color: $white;
        }
        .header__utility-nav{
            svg{
                path:not(.sun){
                    fill: $brand-primary;
                    //stroke: $brand-primary;
                    @media all and (min-width: $menu-break) {
                        fill: $white;
                    }
                }
            }
        }
    }
    &.-main-nav-open:not(.-has-hero){
        .header__utility-weather-widget-text{
            @media all and (max-width: $menu-break) {
                color: $brand-primary;
            }
        }
        .header__utility-weather-toggle{
            path:not(.sun){
                fill: $white;
                @media all and (min-width: $menu-break) {
                    fill: $brand-primary;
                }
            }
            svg circle{
                fill: $brand-primary;
                @media all and (min-width: $menu-break) {
                    fill: $white;
                }
            }
        }
    }
    &.-search-open,
    &.-mountain-report-open{
        .header__utility-nav{
            svg{
                path:not(.sun){
                    fill: $white;
                    //stroke: $white;
                }
            }
            .header__utility-weather-toggle{
                svg{
                    path:not(.sun){
                        fill: $brand-primary;
                        //stroke: $white;
                    }
                }
            }
        }
    }
    &.-scroll-hide{
        transform: translateY(-100%);
    }
    .menu-open &{
        //background-color: $uot-light-orange-50;
    }
    svg{
        path:not(.sun){
            transition: all .35s;
            fill: $brand-primary;
            //stroke:$brand-primary;
        }
    }
    div[class^='luma-'] {
        .luma-preview {
            margin-bottom: 0;
        }
    }
}

.header__menu-btn{
    cursor: pointer;
	display: inline-block;
    position: relative;
	width: 55px;
	height: 55px;
    border: none;
    background-image: none;
    padding: 0;
    background-color: transparent;
    z-index: $btn-z-index;
    .-dark &{
        @media all and (max-width: $bp-med) {
            //background-color: $color-white;
        }
	}
	&:focus{
		outline: 1px dotted $brand-primary--50;
	}
	&.-close-menu{
		// left: auto;
		// right: 13px;
		z-index: 4;
	}

}

.header__hamburger{
	position: absolute;
	top: 42%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 27px;
    height: 2px;
    background-color: $brand-primary;
    transition: all .35s;
    .-has-hero &,
    .-search-open &,
    .-mountain-report-open &,
    .-scrolled.-search-open &,
    .-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-search-open &{
        background-color: $white;
    }
    .-has-hero.-scrolled &{
        background-color: $brand-primary;
    }
	&:before, &:after{
        content: '';
        height: 100%;
        position: absolute;
        background-color: inherit;
        left: 0;
        //transition: all .35s;
    }
    &:before{
        top: -8px;
        width: 100%;
    }
    &:after{
         width: 100%;
         top: 8px;

    }
    .-active &,
    .-has-hero.-scrolled .-active &{
        background-color: rgba(255,255,255,0);
        &:after{
            background-color: $brand-primary;
            top: 0;
            transform: rotate(-45deg);
        }
        &:before{
            background-color: $brand-primary;
            top: 0;
            width: 100%;
            transform: rotate(45deg);
        }
    }
    .-has-homepage &{
        @media all and (min-width: $menu-break) {
            background-color: $menu-btn-color;
        }
    }
    .-has-homepage .-active &{
        background-color: rgba(255,255,255,0) !important;
        &:after{
            background-color: $menu-btn-active-color;
            top: 0;
            transform: rotate(-45deg);
        }
        &:before{
            background-color: $menu-btn-active-color;
            top: 0;
            width: 100%;
            transform: rotate(45deg);
        }
    }
    .-search-open .-active &{
        &:before, &:after{
            background-color: $white;
        }
    }

}
.header__menu-text{
    display: inline-block;
    color: $brand-primary;
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    .-has-hero &,
    .-search-open &,
    .-scrolled.-search-open &,
    .-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-search-open &{
        color: $white;
    }
    .-has-hero.-scrolled &,
    .-has-hero.-main-nav-open &,
    .-has-homepage.-main-nav-open &{
        color: $brand-primary;
    }
    .header__menu-btn.-active &{
        display: none;
    }
    .-has-homepage &{
        @media all and (min-width: $menu-break) {
            color: $menu-btn-color;
        }
    }
}
.header__group-l{
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.header__branding-link{
    margin-left: 10px;
    z-index: $btn-z-index;
    @media all and (min-width: $menu-btn-toggle) {
        margin-left: 30px;
    }
    .-has-hero &{
        svg{
            path:not(.sun){
                fill: $white;
                &.sun{
                    fill: auto;
                }
            }
        }
    }
    .-has-hero.-scrolled &,
    .-has-hero.-main-nav-open &,
    .-main-nav-open &{
        svg{
            path:not(.sun){
                fill: $header-logo-fill;
                
            }
        }
    }
    svg{
        path:not(.sun){
            transition: all .35s;
            fill: $header-logo-fill;
            
        }
    }
}
.header__logo{
    &.-desktop{
        @media all and (max-width: $menu-btn-toggle) {
            display: none;
        }
    }
    &.-mobile{
        @media all and (min-width: $menu-btn-toggle) {
            display: none;
        }
    }
}

.header__utility-nav-list{
    @include list-fix();
    display: flex;
    align-items: center;
}
.header__utility-nav-list-dropdown{
    @media all and (min-width: $menu-break) {
        position: relative;
    }
}

.header__utility-nav-list-item-group{
    &.-standalone{
        position: relative;
    }
}
.header__utility-weather{
    display: flex;
    align-items: center;
    margin-right: 4px;
    @media all and (min-width: $menu-btn-toggle) {
        margin-right: 20px;
    }
}
.header__utility-weather-widget{
    display: flex;
    align-items: center;
    line-height: 1;
    z-index: $btn-z-index;
    svg{
        width: auto;
        height: 24px;
        margin-right: 6px;
        @media all and (max-width: $menu-btn-toggle) {
            transform: scale(80%);
        }
        
    }
    
}
.header__utility-weather-widget-text{
    font-size: 16px;
    
    @media all and (min-width: $menu-btn-toggle) {
        font-size: 20px;
    }
    .-has-hero &,
    .-search-open &,
    .-main-nav-open &,
    .-mountain-report-open &,
    .-scrolled.-search-open &,
    header.-scrolled.-main-nav-open &,
    .-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-search-open &{
        color: $white;
    }
    .-has-hero.-scrolled &{
        color: $brand-primary;
    }
}
.header__utility-weather-toggle{
    @include button-clear();
    min-width: 30px;
    height: 55px;
    z-index: 10;
    svg path:not(.sun){fill: $white}
    svg circle{fill: $brand-primary}
    header.-has-hero &,
    header.-search-open &,
    header.-main-nav-open &,
    header.-scrolled.-search-open &,
    header.-scrolled.-mountain-report-open &,
     header.-has-hero.-main-nav-open &,
    header.-has-homepage.-main-nav-open &,
    header.-has-hero.-scrolled.-mountain-report-open &,
    header.-has-hero.-scrolled.-search-open &{
        svg path:not(.sun){fill: $brand-primary}
        svg circle{fill: $white}
    }
    header.-scrolled.-mountain-report-open &,
    header.-mountain-report-open &,
    header.-has-hero.-mountain-report-open &,
    header.-has-hero.-scrolled.-mountain-report-open &{
        svg path:not(.sun){
            fill: $brand-primary;
            transform: rotate(45deg);
            transform-origin: center;
        }
        svg circle{fill: $brand-secondary}
    }
    header.-has-hero.-scrolled &
    {
        svg path:not(.sun){fill: $white;}
        svg circle{fill: $brand-primary}
    }
    header.-has-hero.-main-nav-open .header__utility-nav &,
    header.-scrolled.-main-nav-open .header__utility-nav &{
        svg path:not(.sun){
            fill: $white;
            @media all and (min-width: $menu-btn-toggle) {
                fill: $brand-primary;
            }
        }
        svg circle{
            fill: $brand-primary;
            @media all and (min-width: $menu-btn-toggle) {
                fill: $white;
            }
            
        }
        
    }

}
.header__utility-alerts{
    @include button-clear();
    position: relative;
    height: 55px;
    min-width: 44px;
    z-index: $btn-z-index;
}
.header__utility-alerts-bang{
    position: absolute;
    right: 8px;
    top: 12px;
    transition: all .35s;
    transform: scale(0);
    opacity: 0;
    .header__utility-alerts.-has-alerts &{
        transform: scale(1);
        opacity: 1;
    }
}
.header__utility-cart{
    position: relative;
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 14px;
    z-index: $btn-z-index;
    @media all and (max-width: $menu-btn-toggle) {
        display: none;
    }
}
.header__utility-cart-count{
    background-color: $brand-primary;
    color: $white;
    font-size: 10px;
    display: inline-block;
    line-height: 1;
    border-radius: 15px;
    padding: 2px 4px;
    position: absolute;
    right: 8px;
    top: 11px;
    .-has-hero &,
    .-main-nav-open &,
    .-has-hero.-scrolled.-main-nav-open &,
    .-has-hero.-scrolled.-mountain-report-open &,
    .-has-hero.-scrolled.-search-open &{
        @media all and (min-width: $menu-break) {
            background-color: $white;
            color: $brand-primary;
        }
    }
    .-has-hero.-scrolled &{
        background-color: $brand-primary;
        color: $white;
    }
}

.header__utility-search{
    @include button-clear();
    height: 55px;
    position: relative;
    min-width: 44px;
    z-index: $btn-z-index;
}

.header__utility-booknow{
    @include button-clear();
    cursor: pointer;
    margin: 0 10px;
    padding: 15px 25px $book-btn-pad-btm;
    position: relative;
    color: $brand-primary;
    border: 1px solid $brand-primary;
    border-radius: 30px;
    font-size: .875rem;
    font-family: $font-alert;
    z-index: $btn-z-index;
    transition: all .35s;
    .-has-hero &,
    .-search-open &,
    .-mountain-report-open &,
    .-main-nav-open &,
    .-has-hero.-search-open.-scrolled &,
    .-has-hero.-mountain-report-open.-scrolled &,
    .-has-hero.-scrolled.-main-nav-open &{
        @media all and (min-width: $menu-break) {
            color: $white;
            border: 1px solid $white;
            &.-active{
                color: $brand-primary;
                background-color: $white;
            }
        }
    }
    .-has-hero.-scrolled &{
        color: $brand-primary;
        border: 1px solid $brand-primary;
        &.-active{
            color: $white;
            background-color: $brand-primary;
        }
    }
    @media all and (max-width: $menu-btn-toggle) {
        display: none;
    }
}
.header__utility-account{
    display: inline-block;
    padding: 15px;
    position: relative;
    z-index: $btn-z-index;
    @media all and (max-width: $menu-btn-toggle) {
        display: none;
    }
}

.header__utility-booknow-dropdown{
    border-radius: 25px;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 70px;
    width: 200px;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity .35s, filter .35s;
    z-index: 3;
    &.-active{
        height: auto;       
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        opacity: 1;
        transform: translateX(-50%);
        
    }
}
.header__utility-booknow-dropdown-wrapper{
    background-color: $white;
    
}
.header__utility-booknow-list{
    @include list-fix();
    padding: 20px;
}
.header__utility-booknow-list-item{
    margin: 0;
    padding: 0;
    &:first-child{
        border-bottom: 1px solid $brand-secondary;
        margin-bottom: 9px;
        padding-bottom: 9px;
    }
}
.header__utility-booknow-list-item-link{
    display: block;
    font-size: .875rem;
    padding: 9px 0;
    line-height: 1;
    font-family: $font-alert;
}