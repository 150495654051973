.visit-info{
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            //margin: 100px 0;
        }
}
.visit-info__wrapper{
    @include gutter1();
}
.visit-info__summary{
    @media all and (min-width: $bp-lrg) {
        display: flex;
        align-items: center;
    }
}
.visit-info__summary-media{
    flex: 0 1 20%;
    margin: 0 0 40px 0;
    @media all and (min-width: $bp-lrg) {
        margin: 0 40px 0 0;
        max-width: 155px;
        height: 155px;
    }
}
.visit-info__summary-media-img{
    object-fit: cover;
    border-radius: 25px 0px;
    height: 100%;
}
.visit-info__summary-content{
    flex: 0 1 80%;
    max-width: 700px;
}

.visit-info__progress{
   margin: 60px 0;
}
.visit-info__progress-wrapper{
    @include gutter1;
}
.visit-info__progress-bar{
    position: relative;
    width: 100%;
    height: 11px;
    background-color: $visit-info-bar-color;
}
.visit-info__progress-marker{
    position: absolute;
    top: 5.5px;
    
}
.visit-info__progress-marker-circ{
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: $visit-info-bar-progress-color;
    transform: translate(-50%, -50%);
}
.visit-info__progress-marker-label{
    font-family: $visit-info-bar-label-font;
    font-size: 20px;
    display: inline-block;
    transform: translate(-3px, -50%);
    margin-top: 10px;
    @media all and (min-width: $bp-lrg) {
        font-size: 32px;
        margin-top: 16px;
        transform: translate(-6px, -50%);
    }
    
}
.visit-info__progress-bar-indicator{
    width: 8%;
    height: 11px;
    background-color: $visit-info-bar-progress-color;
    position: absolute;
    top: 0;
    left: 0;
}