&__table {
    table-layout: fixed;
    width: 100%;

    @media all and (max-width: $bp-med) {
        
        thead {
            display: none;
        }
    }

    tbody {
        position: relative;

        // .isSafari & {
        //     display: block;
        // }

        tr {
            border-bottom: 1px solid $alert-lblue;

            // &:last-child {

            //     .mc-uphill & {

            //         th, td {
            //             padding-bottom: 0;
            //         }
            //     }
            // }
        }
        // &:after {
        //     content: '';
        //     display: block;
        //     height: 3px;
        //     position: absolute;
        //     bottom: 0;
        //     left: -1.5rem;
        //     right: -1.5rem;
        //     background: $brand-primary--5;
            
        //     .mc-uphill & {
        //         background: $white;
        //     }
        // }
    }

    td,
    th {
        padding: rem(calc($comp-pad / 2)) 0;

        @media all and (max-width: $bp-sml) {
            @include font(rem(14px));
        }
    }

    th {
        padding-bottom: rem($comp-pad);
        text-align: left;
    }

    &-lg {
        table-layout: auto;

        tbody {

            tr {
                border-color: $brand-primary--50;
            }
        }

        td,
        th {
            padding: calc($comp-pad * 2) 0;
        }
        td {
            width: 0.1%;
            white-space: nowrap;
        }
        th {
            text-align: left;
        }

        @media all and (max-width: $bp-med) {

            tr {
                @include display(flex, row wrap);
            }

            th {
                width: 100%;
                padding-bottom: 0;
            }

            td {
                padding-top: rem($comp-pad);
            }
        }
    }

    &-route,
    &-status {
        @include display(inline-block);
        @include position;
        @include font(rem(32px), null, $font-h2);
    }

    &-route {
        @include display(inline-flex, row nowrap, null, center);
        white-space: nowrap;
        padding-right: $comp-pad;
        color: $mc-uphill__route-color;
    }

    &-status {
        padding-left: $comp-pad;

        &:before {
            content: '';
            @include display(block);
            @include size(rem(35px));
            @include position(absolute, 1, (top, right), (50%, 100%), translateY(-50%));
            border-radius: 50%;
        }

        &-open:before {
            background: $alert-green;
        }

        &-limited:before {
            background: $alert-yellow;
        }

        &-full:before {
            background: $alert-red;
        }

        @media all and (max-width: $bp-med) {
            margin-left: $mc-uphill__icon-size;
        }
    }

    &-col-svg {
        width: rem(calc($comp-pad * 6));
        white-space: nowrap;
    }
}

&-trails__table {
    @include display(flex, row wrap);

    .mc__table-cell {
        @include display(flex, row nowrap, null, center);
    }

    .mc__table-cell-svg {
        width: rem(48px);
        justify-content: center;
    }

    thead,
    tbody {
        flex: 0 0 100%;
    }

    thead {
        
        tr {
            @include display(flex, row nowrap, space-between);
            width: 100%;
            
            @media all and (max-width: $bp-lrg) {

                th[aria-hidden="true"] {
                    display: none;
                }
            }
            @media all and (min-width: $bp-lrg) {
                
                th {

                    &:first-child {
                        margin-right: auto;
                    }
                    &:nth-child(3) {
                        margin-left: 3rem;
                    }
                    &:last-child {
                        margin-left: auto;
                    }
                }
            }
        }
    }
    tbody {
        @include display(flex, column wrap);
        max-width: calc(100% + #{rem(calc($comp-pad * 2))});
        margin: 0 rem(calc($comp-pad * -1));

        tr {
            display: flex;
            width: 100%;
            margin: 0 rem($comp-pad);

            td {
                display: block;

                &:first-child {
                    margin-right: auto;
                }
            }
        }
        @media all and (min-width: $bp-lrg) {

            tr {
                max-width: calc(50% - rem($comp-pad));
            }
        }
    }
}
&-trails__table.js-mc__table-short thead tr {

    @media all and (min-width: $bp-lrg) {
        width: calc(50% - #{$comp-pad});
    }
}