&__num {
    @include display(inline-block);
    vertical-align: top;
    margin: 0;
    @include font(fluid(32px, 50px), 1, $font-h2, 400);
    white-space: nowrap;

    &-sm {
        @include font(rem(32px));

        + svg {
            margin-top: -0.25em;
        }
    }

    &-md {
        @include font(rem(64px));
    }

    &-lg {
        @include font(fluid(64px, 85px));
    }

    &-xl {
        @include font(rem(85px));
    }

    &-icon {
        @include display(flex, row nowrap, null, center);

        svg {
            margin-right: 1em;
            margin-top: -.25em;
            path{
                stroke: #fff;
            }
            .header.-mountain-report-open .header__utility-nav & {
                &.mc__cell-icon path {
                    stroke-width: 4px;
                    fill: none;
                }
            }
        }

        + p {
            margin-left: calc(1em + #{rem(26px)});
        }
    }

    + .mc__keys {
        margin-top: rem($comp-pad);
    }

    + svg {
        margin-left: rem(calc($comp-pad / 2));
    }
}