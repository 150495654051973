a{
    color: $brand-primary;
    text-decoration: none;
    &:hover{
        color: $brand-primary;
        text-decoration: underline;
    }
    &:focus{
        outline: 1px dotted $brand-primary--50;
    }
    &.triangle {
        @include display(flex, row nowrap, null, center);
        width: 100%;
        white-space: nowrap;
        padding: 1em 0;
        color: $white;
        font-weight: bold;

        &:after {
            @include triangle(right, $white, rem(5px));
            margin-left: 1em;
            transition: margin .35s ease-out;
        }
        @include hover {
            &:after {
                margin-left: 2em;
            }
        }
    }
}