.single-img{
    position: relative;
    &.-padded{
        @include gutter2();
        margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 100px 0;
        }
    }
    
}

.single-img__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media all and (max-width: $bp-lrg) {
        max-height: 720px;
    }
}

.single-img__caption{
    font-size: 12px;
    padding: 12px 26px;
    color: $white;
    background-color: $vid-caption-bg;
    width: 300px;
    position: absolute;
    margin: 0 40px;
    bottom: 0;
    transform: translate(0, 50%);
    right: calc(50% - 190px);
    @media all and (min-width: 600px) {
        left: auto;
        margin: 0;
        right: $comp-pad-lrg;
        bottom: 66px;
        transform: translate(0,0);
        background-color: $white;
        color: $brand-primary;
    }
    .single-img.-padded &{
        position: static;
        margin: 40px auto;
        transform: translateY(0);
        bottom: 0;
        color: $white;
        background-color: $vid-caption-bg;
        @media all and (min-width: 600px) {
            left: auto;
            margin: 0;
            position: absolute;
            right: $comp-pad-lrg;
            transform: translate(0, 50%);
        }
    }
    .single-img.-landscape &{
        @media all and (max-width: $bp-lrg) {
            color: $white;
            background-color: $vid-caption-bg;
        }
    }
    .single-img.-caption-left &{
        @media all and (min-width: 600px) {
            right: auto;
            left: $comp-pad-lrg;
        }
    }
}