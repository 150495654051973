.booking{
    @include position(relative, 2);
    margin: 16px 0;
    @media all and (min-width: $bp-lrg) {
        margin: 32px 0;
    }
    @include placeholder($brand-secondary);

    input[type="text"][readonly] {
        cursor: pointer;
        
        @include hover {
            outline: none;
        }
        @media all and (min-width: $bp-lrg) {
            font-family: $font-alert;
            font-size: 14px;
        }
    }
    input[type="date"]:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        outline: none;
    }
    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        width: rem(50px);
    }
    .booking__toggle-icon {
        display: inline-block;
        margin-left: rem(calc($comp-pad / 3));
        width: 8px;
        height: 2px;
        background-color: $white;
        position: relative;
        transition: all .35s;
        &:after{
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: rotate(90deg);
            transition: all .35s;
            background-color: $white;
        }
    }
    .bar__nav-item.-active .booking__toggle-icon:after {
        transform: rotate(0deg);
    }
    @media all and (min-width: $bp-lrg) {
        /* Always display the drop down caret */
        input[type="date"]::-webkit-calendar-picker-indicator {
            color: #fff;
            background-image: url(../imgs/cal.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        ::-webkit-calendar-picker-indicator {
            color: #fff;

            .booking__widget &,
            .homepage-hero & {
                color: $booking-bar-bg;
            }
        }

        @include placeholder($white);

        &.booking__widget {

            input[type="date"]::-webkit-calendar-picker-indicator {
                color: $booking-bar-bg;
                background-image: url(../imgs/cal.svg);

                @media all and (min-width: $bp-lrg) {
                    color: $booking-bar-bg;
                    background-image: url(../imgs/cal-dark.svg);
                }
            }
            ::-webkit-calendar-picker-indicator {
                color: $booking-bar-bg;
            }
            input[type="date"] {
                color: $booking-bar-bg;
            }
            @include placeholder($booking-bar-bg);
        }
    }

    &__callout {
        
        @include color($brand-primary--50, $white);
        transition: opacity .125s ease-out, padding 0s linear .25s;

        @media all and (min-width: $bp-lrg) {
            border-radius: rem($comp-pad);
            padding: calc($comp-pad * 2);
        }

        &:not(.-open) {
            opacity: 0;
            padding: 0;
        }
        &.-open {
            transition: opacity .5s ease-out .5s, padding 0s linear .25s;
        }
        h3, p{
            @media all and (max-width: $bp-lrg) {
                display: none;
            }
        }
    }

    &__btn {
        @include display(inline-flex, row nowrap, center);
        position: relative;
        padding: 1.1em 1.75em 1em;
        margin-bottom: 2.5em;
        appearance: none;
        border-radius: 30px;
        white-space: nowrap;
        @include font(rem(14px), 1, $font-alert);
        border: 1px solid $white;
        color: $white;
        transition: background-color .35s ease-out, color .35s ease-out;
        text-decoration: none;

        @include hover {
            text-decoration: none;
            cursor: pointer;
            @include color($white, $booking-bar-bg);
        }
        @media all and (max-width: $bp-lrg) {
            display: none;
        }
    }
}
.booking__input-date + svg{
    cursor: pointer;
    path{
        
        @media all and (max-width: $bp-lrg) {
            fill: $booking-bar-bg;
        }
    }
}
.booking__mobile-toggle{
    align-items: center;
    height: 60px;
    border-radius: 30px;
    background-color: $booking-bar-bg;
    display: flex;
    justify-content: space-between;
    padding: 0 rem(16px);
    position: relative;
    z-index: 2;
    @media all and (min-width: $bp-lrg) {
        display: none;
    }
    .booking.-full-bar &{
        display: none;
    }
}
.booking__mobile-toggle-group{
    display: flex;
    align-items: center;

    &-set {
        @include display(flex, row nowrap, null, center);

        .booking__mobile-toggle-group {
            padding: rem(16px);
        }
    }
}
.booking__mobile-date{
    color: #fff;
    font-size: 14px;
    font-family: $font-alert;
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}

.booking__wrapper{
    max-width: rem(1400px);
    margin: 0 auto;
    padding: rem($comp-pad);
}

.booking__bar{

    @media all and (max-width: $bp-lrg) {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        border-radius: 0 0 31px 31px;
        padding: 30px 0 0 0;
        margin: -30px 4px 0 4px;
        overflow: hidden;
        height: 0;
    }
}
.booking__bar-wrapper{

    .booking__widget &,
    .homepage-hero & {
        padding: 0 8px;
    }

    @media all and (min-width: $bp-lrg) {
        align-items: center;
        height: 60px;
        padding: 0 8px;
        border-radius: 30px;
        background-color: $booking-bar-bg;
        display: flex;
        justify-content: space-between;

        .booking__widget &,
        .homepage-hero & {
            padding: 0 8px 0 30px;
            background-color: $white;
        }
    }
}
.booking__input-group{
    display: flex;
    justify-content: space-between;
    padding: 16px 30px;
    align-items: center;
    flex: 0 0 auto;
    max-width: 100%;

    &.-submit{
        display: block;
        padding-right: 0;

        .booking__widget & {
            padding-top: rem(16px);
            padding-bottom: rem(16px);
        }
    }

    @media all and (max-width: $bp-lrg) {
        justify-content: flex-end;

        &.-submit {
            padding-right: 20px;
            border-top: none;
        }
        .booking__input-label {
            margin-right: auto;
        }
    }

    &-set {
        @include display(flex, row wrap, null, center);

        .booking__input-group {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media all and (min-width: $bp-lrg) {
            flex-flow: row nowrap;

            .booking__input-group {
                flex: 0 0 auto;
            }
        }
    }

    @media all and (max-width: $bp-xlrg) {

        .booking__input[type="text"] {
            width: rem(75px);
        }
    }

    .booking__widget & {
        padding: rem(16px);
    }
    .homepage-hero & {
        padding: 8px 15px;
    }

    @media all and (max-width: $bp-lrg) {
        padding: 20px 20px;
        border-top: 1px solid transparentize($color: $brand-secondary, $amount: .75);

        .booking__widget & {
            padding: rem(16px) rem(30px);
        }
        .homepage-hero & {
            padding: 8px 15px;
        }
    }
    &:first-child{
        border-top: none;
    }

    &-wrap {
        @include display(flex, row nowrap, null, center);
    }

    &-divider {
        @include size(1px, 50%);
        background: $white;

        .booking__widget & {
            background: $booking-bar-bg;
        }
    }
}
.booking__arrow{
    padding: 0 rem(8px);

    svg {
        @include display(block);
    }

    @media all and (min-width: $bp-lrg) {

        .booking__widget &,
            .homepage-hero & {
            @include display(block);
            
            svg path {
                fill: $booking-bar-bg;
            }
        }
    }

    @media all and (max-width: $bp-lrg) {
        display: none;
    }
}
.booking__input-ctrl-group{
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.booking__input-label{
    color: $brand-secondary;
    cursor: pointer;
    display: inline-block;
    margin: 0 20px 0 0;
    font-family: $font-alert;
    font-size: 14px;
    @media all and (min-width: $bp-lrg) {
        color: #fff;

        .booking__widget &,
            .homepage-hero & {
            color: $brand-secondary;
        }
    }
    &.-mobile-only{
        @media all and (min-width: $bp-lrg) {
            display: none;
        }
    }
}
.booking__input{
    color: $brand-secondary;
    background-color: #fff;
    border: none;
    @media all and (min-width: $bp-lrg) {
        color: #fff;
        background-color: $booking-bar-bg;

        .booking__widget &,
            .homepage-hero & {
            color: $booking-bar-bg;
            background-color: $white;
        }
    }
    &[type="number"]{
        appearance: none;
        -webkit-appearance: none;
        text-align: center;
        &::-webkit-inner-spin-button { 
            display: none;
        }
        &:focus{
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            outline: none;
        }
    }
    &[type="text"] {
        width: rem(75px);
        height: rem(30px);
    }
    .booking__widget & + svg path {
        fill: $booking-bar-bg;
    }
}
.booking__input-btn{
    background-color: transparent;
    border: 1px solid $brand-secondary;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    @media all and (min-width: $bp-lrg) {
        border: 1px solid #fff;

        .booking__widget &,
            .homepage-hero & {
            border-color: $booking-bar-bg;
        }
    }
}
.booking__mobile-toggle-btn{
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 30px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: relative;
}
.booking__mobile-toggle-btn-indicator{
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .35s;
    &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        transition: all .35s;
        background-color: #fff;
    } 
    .booking__mobile-toggle-btn.-active &:after{
        transform: rotate(0deg);
    }  
}
.booking__input-submit-btn{
    background-color: transparent;
    border: 1px solid $brand-secondary;
    border-radius: 20px;
    color: $brand-secondary;
    display: block;
    font-size: 14px;
    padding: 10px 30px;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: all .3s ease-out;
    @media all and (min-width: $bp-lrg) {
        display: inline-block;
        border-color: $white;
        color: #fff;

        .booking__widget &,
        .homepage-hero & {
            border-color: $booking-bar-bg;
            color: $booking-bar-bg;
        }
    }
    &:hover{
        color: #fff;
        background-color: $brand-secondary;
        text-decoration: none;
        @media all and (min-width: $bp-lrg) {
            color: $brand-secondary;
            background-color: #fff;

            .booking__widget &,
            .homepage-hero & {
                color: $white;
                background-color: $booking-bar-bg;
            }
        }
    }
}

.booking__input-btn-indicator{
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: $brand-secondary;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .35s;

    @media all and (min-width: $bp-lrg) {
        background-color: #fff;

        .booking__widget &,
            .homepage-hero & {
            background-color: $booking-bar-bg;
        }
    }
    &.-plus:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(90deg) translateZ(0);
        backface-visibility: hidden;

        transition: all .35s;
        background-color: $brand-secondary;
        @media all and (min-width: $bp-lrg) {
            background-color: #fff;

            .booking__widget &,
            .homepage-hero & {
                background-color: $booking-bar-bg;
            }
        }
    }
}

.homepage-hero + div .booking__widget,
.homepage-hero + .booking__widget {
    margin: 0;
    background: $brand-primary;
    .booking__callout{
        @media all and (max-width: $bp-lrg) {
            position: relative;
            top: -30px;
        }
    }
    .booking__wrapper {
        padding-top: 0;
        padding-bottom: 0;

        .bar__nav {
            transform: translateY(-50%);
            margin: 0;
        }
        h3 {
            margin-top: 0;
        }
    }
}