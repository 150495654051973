$search-break : $bp-lrg;
.search{
    background-color: $brand-primary;
    height: 0vh;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity .35s;
    z-index: -1;
    .-main-nav-open &{
        //z-index: -1;
    }
    a:focus{
        outline: none;              
    }
    &.-active{
        opacity: 1;
    }
    .-search-open &{
        z-index: 2;
    }
}
.search__wrapper{
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search__input-group{
    display: flex;
    
    
    flex-direction: column;
    @media all and (min-width: $search-break) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
.search__input-field{
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 30px;
    flex: 0 1 75%;
    @media all and (min-width: $search-break) {
        ustify-content: center;
    }
}
.search__form-input{
    font-size: 20px;
    border: none;
    background-color: transparent;
    color: $brand-primary;
    padding: 12px;
    flex: 0 1 72%;
    font-family: $font-alert;
    max-width: 72%;
    &:focus{
        outline: none;
    }
    @media all and (min-width: $search-break) {
        flex: 0 1 95%;
        max-width: 95%;
    }
}
.search__form-input-submit{
    background-color: $white;
    font-size: 14px;
    color: $brand-primary;
    border-radius: 25px;
    padding: 15px;
    border: 1px solid $brand-primary;
    line-height: 1;
    font-family: $font-alert;
    @media all and (min-width: $search-break) {
        padding: 15px 20px;
    }
}
.search__close{
    @include button-clear();
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.search__close-text{
    color: $white;
    font-size: 10px;
    display: inline-block;
    margin-top: 4px;
}