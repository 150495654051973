&__ctrl {
    padding: rem(calc($comp-pad / 4));

    &-btn {
        @include display(inline-flex, row nowrap, center);
        position: relative;
        max-width: rem(230px);
        padding: 1.1em 1.75em 1em;
        appearance: none;
        border-radius: 30px;
        white-space: nowrap;
        @include font(rem(14px), 1, $font-alert);
        border: 1px solid transparent;
        @include color($white, $brand-primary, null, $brand-primary, $brand-primary, $brand-primary);
        transition: background-color .35s ease-out, color .35s ease-out;

        &.-active {
            @include color($brand-primary, $white, null, $white, $white, $white);
        }

        @include hover {
            text-decoration: none;
            cursor: pointer;
            @include color($brand-primary, $white, null, $white, $white, $white);
        }
    }

    &-tab-btn {
        padding: .75em 1.35em .65em;

        .mc-weather &,
        .mc-lifts & {
            @include color(transparent, $white);
            
            &.-active {
                background-color: $brand-secondary;
            }

            @include hover {
                background-color: $brand-secondary;
            }
        }

        .mc-uphill & {
            @include color(transparent, $mc-uphill__ctrl-color);
            
            &.-active {
                @include color($mc-uphill__ctrl-color, $white);
            }

            @include hover {
                @include color($mc-uphill__ctrl-color, $white);
            }
        }
    }

    &-slide-btn {
        margin: rem(calc($comp-pad / 4));
        border-color: $brand-primary;
    }

    &s {
        @include list-fix;
        @include display(flex, row wrap);
        margin: 0 calc($comp-pad / -4);

        &-slides {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
}