.homepage-hero{
    position: relative;
    padding-bottom: 0px;
}
.homepage-hero__branding{
    position: absolute;
    top: $hero-branding-pos;
    left: 50%;
    width: 75%;
    transform: translate(-50%, 0);
    z-index: 1;
    @media all and (min-width: $bp-lrg) {
        width: 60%;
        top: $hero-branding-pos--lrg-scrn;
    }
    svg{
        width: 100%;
        height: auto;
    }
}
.homepage-hero__branding-extra{
    position: absolute;
    transform: translate(-80%, 0);
    @media all and (max-width: $bp-lrg) {
        width: 75px;
        transform: translate(-50%, 30%);
    }
}
.homepage-hero__intro{
    position: relative;
    overflow: hidden;
}
.homepage-hero__media{
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 20%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 40, 86, 0.40) 0%, rgba(255, 255, 255, 0.00) 84.38%);
        mix-blend-mode: multiply;
        z-index: 2;
        @media all and (min-width: $bp-lrg) {
            transform: translate($hero-img-offset, 0px);
        }
    }
}
.homepage-hero__video-container{
    // position: relative;
    background-color: #000;
    // overflow: hidden;
    // width: 100%;
    // height: 0;
    // padding-bottom: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 160vw;
    max-height: 900px;
    overflow: hidden;
    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 40, 86, 0.40) 0%, rgba(255, 255, 255, 0.00) 84.38%);
        mix-blend-mode: multiply;
        z-index: 2;
    }
    @media all and (min-width: $bp-lrg) {
        max-height: none;
        height: 100vw;
        transform: translate($hero-img-offset, 0px);
    }
}
.homepage-hero____video{
    position: absolute;
    z-index: 1;
    top: 50%;
    left:50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
}
.homepage-hero__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media all and (max-width: $bp-lrg) {
        max-height: 720px;
    }
    @media all and (min-width: $bp-lrg) {
        transform: translate($hero-img-offset, 0px);
    }
}
.homepage-hero__content{
    background-color: $brand-primary;
    padding: 80px 0;
    margin-top: -65px;
    @media all and (min-width: $bp-lrg) {
        padding: 240px 0;
        margin-top: -110px;
    }
}
.homepage-hero__content-wrapper{
    padding: 0 25px;
    @media all and (min-width: $bp-lrg) {
        padding: 0 14%;
    }
    *{
        color: $hero-content-color;
        font-family: $font-home-h1-1;
        font-size: #{fluid(36px, 113px, 375px, 2200px)};
        margin: 0;
        line-height: 1.27;
        img{
            width: 41px;
            height: 41px;
            @media all and (min-width: $bp-lrg) {
                width: 80px;
                height: 80px;
            }
        }
        img:nth-child(3n - 2){
            border-radius: $hero-link-img-radius;
        }
        img:nth-child(3n - 0){
            border-radius: $hero-link-img-radius 0 $hero-link-img-radius 0;
        }
        img:nth-child(3n - 1){
            border-radius: $hero-link-img-radius $hero-link-img-radius $hero-link-img-radius 0;
        }
    }
    p{
        color: $hero-content-color;
        font-family: $font-home-h1-1;
        font-size: #{fluid(36px, 113px, 375px, 2200px)};
        margin: 0;
        line-height: 1.27;
    }
    a{
        color: $white;
        display: inline-block;
        line-height: 1;
        font-family: $font-home-h1-2;
        position: relative;
        text-decoration: none;
        transition: color .5s;
        &:hover{
            text-decoration: none;
            color: $brand-secondary;
        }
        &:after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $hero-link-underline-color;
            bottom: -2px;
            left: 0;
        }
    }
}
