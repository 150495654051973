&-forecast {
    padding: rem($comp-pad * 2) 0;
    overflow: hidden;

    &__wrap {
        padding: 0 0 0 $comp-pad;
        @media all and (min-width: $bp-lrg) {
            padding: 0 0 0 $comp-pad-lrg;
        }

        > * {
            @include display(inline-block);
            vertical-align: middle;

            &:first-child {
                margin-right: .5em;
            }
        }
    }

    &__slider {

        .mc-forecast & {
            @include display(flex, row wrap, space-between, center);
        }
        .swiper-wrapper {
            margin-top: rem($comp-pad);
        }
        .swiper-slide {
            flex: 0 0 100%;
            max-width: rem(275px);

            @media screen and (min-width: $bp-med) {
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
            }
            @media screen and (min-width: $bp-lrg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
            @media screen and (min-width: $bp-xlrg) {
                flex: 0 0 20%;
                max-width: 20%;
            }
            @media screen and (min-width: rem(1800px)) {
                flex: 0 0 calc(100% / 6);
                max-width: calc(100% / 6);
            }
            height: auto;
            padding: calc($comp-pad * 1.5);
            border-radius: 25px 0 25px 0;
            border: 1px solid transparent;
            @include color($brand-primary--5, $brand-primary);
            transition: background-color .2s ease-out, color .2s ease-out, border-color .2s ease-out;
    
            h4 {
                border-bottom-color: $brand-primary;
                transition: border-color .35s ease-out;
            }
            .mc__h4 {
                font-family: inherit;
            }
            svg path {
                transition: all .35s ease-out;
                stroke: $brand-primary;
            }
    
            &-active {
                @include color($brand-primary, $white, null, $white, $white, null, $white);
    
                h4 {
                    border-bottom-color: $white;
                }
            }
    
            &s {
                padding: 2px 0;
            }
        }
    }
}