//COLOR

$brand-primary: #1d4f91;
$brand-primary--50: #97aecc;
$brand-primary--25: rgba($brand-primary, .25);
$brand-primary--5: #f4f6fa;

$brand-secondary: #fccf45;
$brand-secondary--5: #fffaec;

//TODO - FIGURE OUT REPLACEMENT IN COMPS
//$brand-tertiary: #ffc629;

$gray: #d9d9d9;
$white: #fff;

//Design specific variable adjustments
//FONTS
$font-home-h1-1: "AspiraThin";
$font-home-h1-2: "AspiraThin";
$font-h1: "Chronicle Display A", "Chronicle Display B";
$font-h2: "AspiraLight";
$font-h3: "AspiraLight";
$font-h4: "AspiraMedium";
$font-h5: "AspiraMedium";
$font-paragraph: "AspiraRegular";
$font-alert: "AspiraMedium";
$font-h1-color: $brand-primary;
$font-h2-color: $brand-primary;

//FOOTER
$footer-logo-margin: 0;

//HEADER
$header-logo-fill: #000;
$book-btn-pad-btm: 13px;

//MENU
$menu-top-link: #7996BE;
$menu-seasontoggle-link: $brand-primary;
$menu-double-link: #7996BE;
$menu-subheading-link: $brand-secondary;
$menu-subheading-link-mobile: $brand-primary;

//SEASON TOGGLE
$toggle-tooltip-bg: $brand-secondary;
$toggle-tooltip-text: $brand-primary;
$toggle-tooltip-opacity: 1;

//BREADCRUMB
$breadcrumb-active-color: $brand-primary;
$breadcrumb-pad-top: 8px;

//ALERT
$alert-yellow: $brand-secondary;
$ctrl-color: $brand-primary;
$alert-yellow: #FFC629;
$ctrl-color: $brand-primary;
$alert-red: #cf0000;
$alert-green: #42c557;
$alert-blue: #1d4f91;
$alert-lblue: #97AECC;
$alert-copy-color: $alert-blue;

//VIDEO
$vid-caption-bg: $brand-primary;

//RICH TEXT
$block-quote-color: $brand-primary;
$bq-opacity: .25;

//FEATURE TEXT
$feature-blue: $brand-primary;
$feature-gold: $brand-secondary;
$feature-lightbg: $brand-secondary--5;
$feature-copy-dark: $brand-primary--50;
$feature-copy-gold: $brand-primary;
$feature-graphic-default: $brand-primary;
$large-font: $font-h1;

//HOMEPAGE HERO
$hero-branding-pos: 37%;
$hero-branding-pos--lrg-scrn: 24%;
$hero-link-underline-color: $brand-secondary;
$hero-img-offset: 85px;
$hero-link-img-radius: 40px;
$hero-content-color: $white;
$hero-menu-text-color: $brand-primary;
$hero-menu-link-opacity: .5;
$menu-btn-color: $brand-primary !important;
$menu-btn-active-color: $brand-primary;


//LOCAL NAV
$nav-color: $brand-primary;
$subnav-color: $white;
$nav-dropdown-color: $brand-primary--50;

//THREE IMG
$three-img-blue: $brand-primary;
$three-img-lt-blue: $brand-primary--50;

//PAGE TEMPLATE VARS
$booking-bar-bg: $brand-secondary;
$ptc-two-col-heading-color: $brand-primary;
$ptc-two-col-cta-color: $brand-primary;
$ptc-intro-heading-color: $brand-primary;

//IMAGE PARAGRAPH SLIDER
//$img-pg-slider-color: $brand-secondary;

//ACCORDION
$acord-heading-color: $brand-primary;

//FEATURE MEDIA
$feature-img-slider-btn-color: $brand-primary;
// WEDDINGS
$weddings__filter-select-background-color: $brand-primary--50;
$weddings__filter-select-text-color: $brand-primary--5;
$weddings__filter-select-option-highlight-color: darken($weddings__filter-select-background-color, 25%);
$weddings__filter-ctrl-color: $brand-primary;

// MOUNTAIN CONDITIONS
$mc-hero__heading-color: $brand-secondary;
$mc-uphill__route-color: $brand-primary;
$mc-uphill__heading-color: $brand-secondary;
$mc-uphill__ctrl-color: $brand-primary;
$mc-lifts__h2-color: $brand-secondary;
$mc-lifts__h3-color: $brand-primary;
$mc-trails__h2-color: $brand-secondary;
$mc-trails__h3-color: $mc-lifts__h3-color;
$mc-trails__btn-color: $brand-primary;
$mc-forecast__heading-font: $font-h1;
$mc-forecast__btn-color: $brand-primary;
$mc-graph__label-color: $brand-primary;
$mc__h2-color: $brand-secondary;

// DINING DETAIL
$dining__color: $brand-primary;
$dining__bg-color: $brand-primary--5;
$dining__tag-color: $brand-primary--50;
$dining__font: $font-paragraph;

// LOAD MORE
$load-more__color: $brand-primary;

//CARDS
$card-secondary-color: $brand-primary;
$vert-card-padding: 34px 26px 0;
$card__fw-color: $brand-primary;
$card__reverse-heading-color: $white;
$card__reverse-heading-color: $white;
$custom-card__slider-scrollbar-color: $brand-primary;
$swiper__scrollbar-color: $brand-primary;
$custom-card__meta-color: $brand-primary;

//FEATURE ITEM
$feature-item-text-color: $brand-primary;

// FILTERS
$filters__color: $brand-primary;

// WEBCAMS
$webcam__details-color: $brand-primary;

// ACCOUT
$visit-info-bar-color: $brand-primary--5;
$visit-info-bar-progress-color: $brand-primary--50;
$account-cta-color: $brand-primary;
$visit-info-bar-label-font: $font-h1;
$achievement-info-label-alt-color: $brand-primary;

// INTERACTIVE MAP
$map-info-cta-color: $brand-primary;