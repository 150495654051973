.welcome{
    margin: 50px 0;
        @media all and (min-width: $bp-lrg) {
            margin: 70px 0 30px;
        }
}
.welcome__wrapper{
    @include gutter1();
}
.welcome__intro{
    @extend .h3;
}

.welcome__dismissable{
    overflow: hidden;
    &.-dismissed{
        height: 0;
    }
}
.welcome__dismissable-wrapper{
    background-color: $brand-primary--5;
    border-radius: 25px 0px;
    padding: 60px 30px;
    position: relative;
}
.welcome__dismiss-btn{
    background-color: transparent;
    border: none;
    padding: 16px;
    position: absolute;
    right: 0px;
    top: 0px;
}
.welcome__dismissable-cta{
    margin-top: 26px;
}
.welcome__dismissable-cta-btn{
    border: 1px solid $account-cta-color;
    color: $account-cta-color;
    font-size: 14px;
    font-family: $font-alert;
    padding: 12px 16px;
    border-radius: 25px;
    transition: all .35s;
    &:hover{
        text-decoration: none;
        background-color: $account-cta-color;
        color: #fff;
    }
}