
// Variables
$mc-uphill__icon-size: rem(35px);
$mc-parking__graph-height: rem(60px);

.mc {

    // Typography
    @import 'mountain-conditions/type/heading';
    @import 'mountain-conditions/type/number';
    @import 'mountain-conditions/type/paragraph';
    @import 'mountain-conditions/type/timestamp';

    // Grid
    @import 'mountain-conditions/grid/row';
    @import 'mountain-conditions/grid/cell';

    // Components
    @import 'mountain-conditions/components/alert';
    @import 'mountain-conditions/components/button';
    @import 'mountain-conditions/components/callout';
    @import 'mountain-conditions/components/control';
    @import 'mountain-conditions/components/flood';
    @import 'mountain-conditions/components/graph';
    @import 'mountain-conditions/components/icon';
    @import 'mountain-conditions/components/key';
    @import 'mountain-conditions/components/tab';
    @import 'mountain-conditions/components/table';

    // Sections
    @import 'mountain-conditions/sections/weather';
    @import 'mountain-conditions/sections/forecast';
    @import 'mountain-conditions/sections/uphill';
    @import 'mountain-conditions/sections/parking';
    @import 'mountain-conditions/sections/lifts';
    @import 'mountain-conditions/sections/trails';
}