/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Aspira Medium by Durotype
 * URL: https://www.myfonts.com/products/medium-aspira-144889
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Aspira Thin by Durotype
 * URL: https://www.myfonts.com/products/thin-aspira-144915
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Aspira Light by Durotype
 * URL: https://www.myfonts.com/products/light-aspira-144883
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Aspira Regular by Durotype
 * URL: https://www.myfonts.com/products/regular-aspira-144841
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */


  
 @font-face {
    font-family: "AspiraThin";
    src: url('../fonts/AspiraThin/font.woff2') format('woff2'), url('../fonts/AspiraThin/font.woff') format('woff');
  }
  @font-face {
    font-family: "AspiraLight";
    src: url('../fonts/AspiraLight/font.woff2') format('woff2'), url('../fonts/AspiraLight/font.woff') format('woff');
  }
  @font-face {
    font-family: "AspiraMedium";
    src: url('../fonts/AspiraMedium/font.woff2') format('woff2'), url('../fonts/AspiraMedium/font.woff') format('woff');
  }
  @font-face {
    font-family: "AspiraRegular";
    src: url('../fonts/AspiraRegular/font.woff2') format('woff2'), url('../fonts/AspiraRegular/font.woff') format('woff');
  }
  
