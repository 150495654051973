&__btn {
    @include display(inline-flex, row nowrap, center);
    position: relative;
    padding: 1.1em 1.75em 1em;
    appearance: none;
    border-radius: 30px;
    white-space: nowrap;
    text-align: center;
    @include font(rem(14px), 1, $font-alert);
    border: 1px solid $brand-primary;
    @include color($white, $brand-primary, null, $brand-primary, $brand-primary, $brand-primary);
    transition: background-color .35s ease-out, color .35s ease-out;

    &.-active {
        @include color($brand-primary, $white, null, $white, $white, $white);
    }

    @include hover {
        text-decoration: none;
        cursor: pointer;
        @include color($brand-primary, $white, null, $white, $white, $white);
    }

    &-fill {
        @include display(flex);

        &-xs {
            @media all and (max-width: $bp-sml) {
                @include display(flex);
            }
        }
    }

    &s {
        padding: 0 $comp-pad 0 0;
        @media all and (min-width: $bp-lrg) {
            padding: 0 $comp-pad-lrg 0 0;
        }

        @media all and (max-width: $bp-sml) {
            order: 1;
            width: 100%;
            margin-top: $comp-pad;
        }
    }
    
    .mc-weather & {
        margin-top: rem(calc($comp-pad / 2));
        @include color(transparent, $white, $white);

        @include hover {
            @include color($white, $brand-primary, $white);
        }
    }
}

&-forecast {

    .mc__btn,
    .mc__ctrl-slide-btn,
    .mc__ctrl-tab-btn {
        @include color($white, $mc-forecast__btn-color, $mc-forecast__btn-color, $mc-forecast__btn-color, $mc-forecast__btn-color, $mc-forecast__btn-color);

        &.-active {
            @include color($mc-forecast__btn-color, $white, $mc-forecast__btn-color, $white, $white, $white);
        }

        @include hover {
            @include color($mc-forecast__btn-color, $white, $mc-forecast__btn-color, $white, $white, $white);
        }
    }

    .mc__ctrl-slide-btn-next {
        @include color($mc-forecast__btn-color, $white, $mc-forecast__btn-color, $white, $white, $white);
    }

    .mc__ctrl-tab-btn {
        border: 0;
    }
}