$breadcrumb-break : $bp-lrg;
.breadcrumbs{
    margin: -1rem 0 1rem 0;
    @media all and (min-width: $breadcrumb-break) {
    }
}
.breadcrumbs__wrapper{
    @include gutter2();
}
.breadcrumbs__list{
    @include list-fix();
    display: flex;
}
.breadcrumbs__list-item{
    margin: 0;
    padding: 0 20px 0 0;
    display: inline-block;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 0; 
        height: 0; 
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid $breadcrumb-active-color;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
    }
    &:last-child:after{
        display: none;
    }
}
.breadcrumbs__list-item-link{
    display: block;
    font-family: $font-alert;
    font-size: .875rem;
    color: $breadcrumb-active-color;
    opacity: 1;
    line-height: 1;
    .breadcrumbs__list-item:last-child &{
        opacity: 1; 
    }
    &.-abbreviated{
        &:before{
            content:"...";
            display: inline-block;
            padding: 6px 0;
        }
        .breadcrumbs__text{
            display: none;
        }
    }
    &:hover{
        color: $breadcrumb-active-color;
        opacity: 1;
        text-decoration: underline;
    }
}
.breadcrumbs__text{
    overflow: hidden;
    width: auto;
    display: block;
    transition: width .35s;
    padding: $breadcrumb-pad-top 0 6px;
}