@use "sass:math";

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {

    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }
    @return $number;
}

@function rem($px) {
    $px: strip-unit($px);
    $rems: math.div($px, 16) * 1rem;
    @return $rems;
}


@function round($number, $decimals: 0) {
    $n: 1;
    @if $decimals > 0 {
        @for $i from 1 through $decimals {
            $n: $n * 10;
        }
    }
    @return math.div(math.round($number * $n), $n);
}

@function fluid($min-size, $max-size, $min-breakpoint : 375px, $max-breakpoint : 1900px, $unit: vw) {
    $slope: math.div($max-size - $min-size, $max-breakpoint - $min-breakpoint);
    $slope-to-unit: round($slope * 100, 2);
    $intercept-rem: round(rem($min-size - $slope * $min-breakpoint), 2);
    $min-size-rem: round(rem($min-size), 2);
    $max-size-rem: round(rem($max-size), 2);
    @return clamp(#{$min-size-rem}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size-rem});
}

/// Returns the opposite direction of each direction in a list
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction( $direction ) {
    $direction-map: (
        'top':    'bottom',
        'right':  'left',
        'bottom': 'top',
        'left':   'right',
        'center': 'center',
        'ltr':    'rtl',
        'rtl':    'ltr'
    );

    $opposite-direction: map-get($direction-map, $direction);
    @if $opposite-direction { 
        @return $opposite-direction;
    } @else {
        @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
}

@mixin sr-only() {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@mixin list-fix($margin: 0, $padding: 0, $type: none){
    margin: $margin 0;
    padding-left: 0;
    list-style-type: $type;
}
@mixin no-gutter(){
    padding: 0;
}
@mixin gutter1(){
    padding: 0 $comp-pad;
    @media all and (min-width: $bp-lrg) {
        // 75px on comps, but percentate for flex
        padding: 0 $comp-pad-lrg;
    }
}
@mixin gutter2(){
    padding: 0 $comp-pad;
    @media all and (min-width: $bp-lrg) {
        // 150px on comps, but percentate for flex
        padding: 0 $comp-pad-med;
    }
}

@mixin button-clear(){
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin fluid-font-size($min, $max, $fallback, $min-screen: $min-screensize, $max-screen: $max-screensize){
    font-size: #{$fallback}px;
    font-size: calc(#{$min} + (#{$max} - #{$min}) * (100vw - #{$min-screen}) / (#{$max-screen} - #{$min-screen}));
}

/// Flex shorthand mixin
/// @param {Display} $display ['flex'] - Display behavior, can be any valid CSS3 display value (https://developer.mozilla.org/en-US/docs/Web/CSS/display#syntax) such as 'flex', 'block', or 'none'
/// @param {Flex Flow} $flex-flow [null] - Flex direction and wrap
/// @param {Justify Content} $justify-content [null] - Horizontal alignment of flex children
/// @param {Align Items} $align-items [null] - Vertical alignment of flex children
@mixin display( $display: flex, $flex-flow: null, $justify-content: null, $align-items: null ) {
    display: $display;
    flex-flow: $flex-flow;
    justify-content: $justify-content;
    align-items: $align-items;
}

/// Positioning shorthand mixin
/// @param {Position} $position ['relative'] - Position CSS property value
/// @param {Z Index} $z-index [null] - Z-index CSS property value
/// @param {Directions} $directions [null] - List of directions for offsets
/// @param {Offsets} $offsets [null] - List of offsets in order and $directions and with the same count
/// @param {Transform} $transform [null] - Transform CSS property value, such as 'translateY: -50%'
@mixin position( $position: relative, $z-index: null, $directions: null, $offsets: null, $transform: null ) {
    position: $position;
    z-index: $z-index;
    @if ($directions and $offsets) {
        @each $direction in $directions {
            $i: index($directions, $direction);
            @if (length($offsets) > ($i - 1)) {
                #{$direction}: #{nth($offsets, $i)};
            }
        }
    }
    transform: $transform;
}

/// Hover helper mixin
/// @content - CSS for hover/focus states 
@mixin hover {
    &:hover,
    &:focus {
        @content;
    }
}

/// Font shorthand mixin
/// @param {Font Size} $font-size - Font size property
/// @param {Line Height} $line-height [null] - Line height property
/// @param {Font Family} $font-family [null] - Font family property
/// @param {Font Weight} $font-weight [null] - Font weight property, such as 'bold' or '300'
/// @param {Letter Spacing} $letter-spacing [null] - Letter spacing property
/// @param {Text Transform} $text-transform [null] - Text transform property, such as 'uppercase'
/// @param {Font Style} $font-style [null] - Font style property, such as 'italic'
@mixin font( $font-size, $line-height: null, $font-family: null, $font-weight: null, $letter-spacing: null, $text-transform: null, $font-style: null ) {
    @if $font-family {
        @if $line-height {
            @if $font-weight {
                @if $font-style {
                    font: #{$font-style} #{$font-weight} #{$font-size}/#{$line-height} #{$font-family};
                } @else {
                    font: #{$font-weight} #{$font-size}/#{$line-height} #{$font-family};
                }
            } @else {
                @if $font-style {
                    font: #{$font-style} #{$font-size}/#{$line-height} #{$font-family};
                } @else {
                    font: #{$font-size}/#{$line-height} #{$font-family};
                }
            }
        } @else {
            @if $font-weight {
                @if $font-style {
                    font: #{$font-style} #{$font-weight} #{$font-size} #{$font-family};
                } @else {
                    font: #{$font-weight} #{$font-size} #{$font-family};
                }
            } @else {
                @if $font-style {
                    font: #{$font-style} #{$font-size} #{$font-family};
                } @else {
                    font: #{$font-size} #{$font-family};
                }
            }
        }
    } @else {
        font-size: $font-size;
        line-height: $line-height;
        font-weight: $font-weight;
        font-style: $font-style;
    }
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
}

/// Color shorthand mixin
/// @param {Background Color} $background-color - Background color property
/// @param {Text Color} $text-color [null] - Color property
/// @param {Border Color} $border-color [null] - Border color property
/// @param {Link Color} $link-color [$text-color] - Color property for nested anchors
/// @param {Link Hover Color} $link-hover-color [$text-color] - Color property for hovered/focused nested anchors
/// @param {Icon Color} $icon-color [$text-color] - Fill property for nested SVGs
@mixin color( $background-color, $text-color: null, $border-color: null, $link-color: $text-color, $link-hover-color: $text-color, $icon-fill-color: null, $icon-stroke-color: null ) {
    background-color: $background-color;
    border-color: $border-color;
    color: $text-color;

    a:not([class*="btn"]) {
        color: $link-color;
        @if $link-color == $text-color and $text-color {
            text-decoration: underline;
        }
        @include hover {
            color: $link-hover-color;
            @if $link-color == $text-color and $text-color {
                text-decoration: none;
            }
        }
    }

    svg path {
        @if ($icon-fill-color) {
            fill: $icon-fill-color;
        }
        @if ($icon-stroke-color) {
            stroke: $icon-stroke-color;
        }
    }
    
}

/// Size shorthand mixin
/// @param {Width} $width - Width in any unit
/// @param {Height} $height [$width] - Height in any unit
@mixin size( $width, $height: $width ) {
    width: $width;
    height: $height;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either 'top', 'right', 'bottom' or 'left'
/// @param {Color} $color [currentcolor] - Triangle color 
/// @param {Length} $size [1em] - Triangle size
@mixin triangle( $direction, $color: currentcolor, $size: 1em ) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either 'top', 'right', 'bottom' or 'left'.";
    }
    @include display(block);
    @include size(0);
    content: '';
    z-index: 2;
    border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;

    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
        border-left:   $perpendicular-borders;
        border-right:  $perpendicular-borders;
    } @else if $direction == right or $direction == left {
        border-bottom: $perpendicular-borders;
        border-top:    $perpendicular-borders;
    }
}

/// Placeholder prefix mixin
/// @param {Color} $color - Text color property
@mixin placeholder($color) {
    input::placeholder {
        color: $color;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $color
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $color
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $color
    }
}