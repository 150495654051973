$nav-break : $bp-lrg;
.main-nav{
    background-color: $white;
    height: 0vh;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity .35s;
    &.-active{
        opacity: 1;
    }
    a:focus{
        outline: none;              
    }
}
.main-nav__container{
    height: 100%;
    overflow: hidden;
    padding: 75px 0 0;
}
.main-nav__img{
    display: none;
    @media all and (min-width: $nav-break) {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 66%;
        height: 100%;
        object-fit: cover;
    }
}
.main-nav__container-wrapper{
    height: 100%;
    overflow-y: auto;
    padding: 0 0 75px 0;
    @media all and (min-width: $nav-break) {
        padding: 42px 75px 42px 150px;
    }
}
.main-nav__list{
    @include list-fix();
    @media all and (min-width: $nav-break) {
        width: 24%;
    }
}
.main-nav__list-item{
    &.-double-link{
        display: flex;
        justify-items: flex-start;
        align-items: center;
    }
    &.-toggle{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media all and (min-width: $nav-break) {
            transform: translateX(-75px);
        }
    }
    &.-pill{
        margin-bottom: 24px;
    }
}
.main-nav__list-item-link{
    color: $menu-top-link;
    display: block;
    padding: 18px 24px;
    font-size: 1.25rem;
    font-family: $font-alert;
    transition: all .35s;
    &:hover,
    &.-item-active{
        text-decoration: none;
        color: $brand-primary;
    }
    .-toggle &{
        font-size: .875rem;
        padding-right: 54px;
        margin-right: -30px;
        z-index: 1;
        color: $menu-seasontoggle-link;
        //opacity: .5;
        &.-active{
            opacity: 1;
        }
    }
    .main-nav__switch + &{
        padding-right: 24px;
        margin-right: 0;
        padding-left: 54px;
        margin-left: -30px;
    }
    .-small &{
        font-size: .875rem;
        color: $brand-primary;
    }
    .-double-link &:last-child{
        color: $menu-double-link;
    }
    .main-nav__list-item.-double-link &:first-child{
        padding-right: 8px;
    }
    .main-nav__list-item.-double-link &:last-child{
        padding-left: 8px;
    }
    .-pill &{
        font-size: .875rem;
        text-align: center;
        padding: 14px;
        margin: 24px 24px 0 24px;
        color: $brand-primary;
        border: 1px solid $brand-primary;
        border-radius: 25px;
        &:hover,
        &.-item-active{
            color: $white;
            background-color: $brand-primary;
            
        }
        &.-item-active{
            @media all and (max-width: $nav-break) {
                border-radius: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    
}
.main-nav__switch{
    display: block;
    background-color: $menu-top-link;
    position: relative;
    width: 60px;
    height: 32px;
    border: 1px solid $menu-top-link;
    border-radius: 30px;
}
.main-nav__switch-indicator{
    display: block;
    width: 28px;
    height: 28px;
    position: absolute;
    border-radius: 30px;
    background-color: $white;
    transition: all .35s;
    left: 50%;
    top: 1px;
    .-winter &{
        left: 1%;
    }
}

.main-nav__link-plus{
    display: inline-block;
    width: 9px;
    height: 2px;
    background-color: $menu-top-link;
    position: relative;
    left: 8px;
    top: -5px;
    transition: all .35s;
    &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(90deg);
        background-color: $menu-top-link;
    }
    .main-nav__list-item-link:hover &,
    .main-nav__list-item-link.-item-active &{
        background-color: $brand-primary;
        &:after{
            background-color: $brand-primary;
        }
    }
    .-item-active &:after{
        transform: rotate(0deg);
    }
    .-pill &{
        width: 7px;
        top: -4px;
        left: 2px;
        background-color: $brand-primary;
        &:after{
            background-color: $brand-primary
        }
    }
    .-pill .main-nav__list-item-link:hover &,
    .-pill .main-nav__list-item-link.-item-active &{
        background-color: $white;
        &:after{
            background-color: $white;
        }
    } 
}

.main-nav__subnav{
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity .35s;
    @media all and (min-width: $nav-break) {
        background-color: $brand-primary;
        position: absolute;
        left: 34%;
        top: 0;
        width: 0%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        //padding: 75px 0 75px;
        //transition: width .35s;
        z-index: 2;
    }
    .-pill &{
        background-color: $brand-primary;
    }
    &.-item-active{
        opacity: 1;
        @media all and (min-width: $nav-break) {
            //width: 66%;
        }
    }
    &.-layered{
        @media all and (min-width: $nav-break) {
            z-index: 1;
        }
    }
}
.main-nav__subwrapper{
    transition: opacity .35s ease-in .35s;
    opacity: 0;
    .-item-active &{
        opacity: 1;
    }
    @media all and (min-width: $nav-break) {
        //margin-top: 225px;
        margin-left: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.main-nav__subnav-list{
    @include list-fix();
    .-pill &{
        padding-bottom: 24px;
    }
    // > .main-nav__subnav-list-item,
    // > .main-nav__subnav-list-item > .main-nav__subnav-list-item-link{
    //     font-size: 1rem;
    //     color: $menu-top-link;
    //     margin: 0;
    // }
}
.main-nav__subnav-group{
    padding: 0 12px;
    flex: 0 1 29%;
}
.main-nav__subnav-group-heading{
    padding: 12px 24px;
    font-size: 1rem;
    color: $menu-subheading-link;
    margin: 0;
    
    @media all and (max-width: $nav-break) {
        color: $menu-subheading-link-mobile;
    }
    a{
        text-decoration: none;
        color: $menu-subheading-link;
        @media all and (max-width: $nav-break) {
            color: $menu-subheading-link-mobile;
        }
        &:hover{
            text-decoration: underline;
            color: $menu-subheading-link;
            @media all and (max-width: $nav-break) {
                color: $menu-subheading-link-mobile;
            }
        }
    }
}
.main-nav__subnav-list-item{
    ul{
        @include list-fix();
    }
}
.main-nav__subnav-list-item-link{
    display: block;
    font-size: 1.25rem;
    padding: 12px 24px;
    margin: 0;
    &:hover{
        @media all and (min-width: $nav-break) {
            color: $white;
            text-decoration: underline;
        } 
    }
    @media all and (min-width: $nav-break) {
        color: $white;
    }
    .-pill &{
        color: $white;
    }    
}