.featured-item{
    position: relative;
    padding-bottom: 0px;
    //min-height: 500px;
    &.-padded{
        //margin-bottom: 50px;
        @media all and (min-width: $bp-lrg) {
            margin-bottom: 100px;
        }
    }
    .weddings__result & {
        height: 100%;
    }  
}

.featured-item__media{
    display: block;
    @include size(100%);
    position: relative;
}

.featured-item__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media all and (max-width: $bp-lrg) {
        max-height: 720px;

        .weddings__result & {
            max-height: none;
        }
    }
}

.featured-item__caption{
    margin: 0 25px;
    transform: translateY(-50px);
    @media all and (min-width: $bp-lrg) {
        margin: 0;
        max-width: 600px;
        transform: translateY(0px);
        position: absolute;
        z-index: 1;
    }
    .featured-item.-caption-left-top &{
        top: 60px;
        left: 60px;
    }
    .featured-item.-caption-right-top &{
        top: 60px;
        right: 60px;
    }
    .featured-item.-caption-right-bottom &{
        bottom: 60px;
        right: 60px;
    }
    .featured-item.-caption-left-bottom &{
        bottom: 60px;
        left: 60px;
    }
    .featured-item.-caption-left-middle &{
        top: 50%;
        left: 60px;
        
        @media all and (min-width: $bp-lrg) {
            transform: translate(0, -50%);
        }
    }
    .featured-item.-caption-right-middle &{
        top: 50%;
        right: 60px;
        
        @media all and (min-width: $bp-lrg) {
            transform: translate(0, -50%);
        }
    }
    .featured-item.-caption-top-center &{
        top: 60px;
        left: 50%;
        
        @media all and (min-width: $bp-lrg) {
            transform: translate(-50%, 0%);
        }
    }
    .featured-item.-caption-bottom-center &{
        bottom: 60px;
        left: 50%;
        
        @media all and (min-width: $bp-lrg) {
            transform: translate(-50%, 0%);
        }
    }
    .featured-item.-caption-center-middle &{
        top: 50%;
        left: 50%;
        
        @media all and (min-width: $bp-lrg) {
            transform: translate(-50%, -50%);
        }
    }
}
.featured-item__caption-link{
    background-color: #fff;
    border-radius: 30px;
    display: block;
    padding: 20px 25px;
    @media all and (min-width: $bp-lrg) {
        padding: 40px 140px 40px 45px;
    }
    &:hover{
        text-decoration: none;
    }
}
.featured-item__caption-heading{
    // font-size: 28px;
    color: $feature-item-text-color;
    font-size: #{fluid(28px, 34px)};
    margin-top: 0;
    padding-right: 120px;
    @media all and (min-width: $bp-lrg) {
        padding-right: 0;
    }
}
.featured-item__caption-text{
    font-size: 14px;
    margin-bottom: 0;
}
.featured-item__caption-arrow{
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color .35s ease-out;
    width: 100px;
    border-radius: 0 30px 30px 0;
    @media all and (min-width: $bp-lrg) {
        border-left: 1px solid transparentize($color: $brand-primary, $amount: 0.75);
        height: 100%;
    }
    svg{ 
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translate(-50%, 0%);
        @media all and (min-width: $bp-lrg) {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        path{
            fill: $feature-item-text-color;
        }
    }
    .featured-item__caption-link:hover &{
        @media all and (min-width: $bp-lrg) {
            background-color: transparentize($color: $feature-item-text-color, $amount: 0.9);
        }
    }
}