&__graph {
    @include position;

    &-bar {
        @include size(100%, $mc-parking__graph-height);
        overflow: hidden;
        border-radius: $mc-parking__graph-height;

        &-canvas {
            @include position(absolute, null, (top, left, right), (50%, 0, 0), translateY(-50%) scaleX(1.1));
        }
    }

    &-cnt {
        @include display(flex, row nowrap, center, center);
        @include position;
        @include size(100%, 17.5rem);

        @media all and (min-width: $bp-med) {
            height: 20rem;
        }

        @media all and (min-width: $bp-xlrg) {
            height: 25rem;
        }
    }
}