.dining {
    margin: rem(calc($comp-pad * 2)) auto rem(calc($comp-pad * 4));

    @media all and (min-width: $bp-lrg) {
        padding: 0 $comp-pad-lrg;
    }

    &__h2 {
        font-family: $dining__font;
        font-size: rem(32px);
        color: $dining__color;
        margin-right: 1em;
    }

    &__content {
        @include display(flex, row wrap, null, center);
        background: $dining__bg-color;
        padding: rem(calc($comp-pad * 2)) rem($comp-pad);

        @media all and (min-width: $bp-lrg) {
            padding: rem(calc($comp-pad * 3)) rem(calc($comp-pad * 4));
        }
    }
    &__tab {
        transition: all .35s ease-out;
    
        &.-active {
            @include display(block);
        }
    
        &:not(.-active) {
            @include display(none);
        }
    
        &s {
            width: 100%;
            margin-top: rem($comp-pad);
    
            .dining__tabs {
                margin-top: rem(calc($comp-pad * 2));
            }
        }
    
        > .mc__row > .mc__cell:only-child {
            max-width: none;
        }
    }
    &__ctrl {
        padding: rem(calc($comp-pad / 4));
    
        &-btn {
            @include display(inline-flex, row nowrap, center);
            position: relative;
            max-width: rem(230px);
            padding: 1.1em 1.75em 1em;
            appearance: none;
            border-radius: 30px;
            white-space: nowrap;
            @include font(rem(14px), 1, $font-alert);
            border: 1px solid transparent;
            @include color(transparent, $dining__color, null, $dining__color, $dining__color, $dining__color);
            transition: background-color .35s ease-out, color .35s ease-out;
    
            &.-active {
                @include color($dining__color, $white, null, $white, $white, $white);
            }
    
            @include hover {
                text-decoration: none;
                cursor: pointer;
                @include color($dining__color, $white, null, $white, $white, $white);
            }
        }
    
        &-tab-btn {
            padding: .75em 1.35em .65em;
        }
    
        &s {
            @include list-fix;
            @include display(flex, row wrap);
            margin: 0 calc($comp-pad / -4);
        }
    }
    &__nav {

        &-tabs {

            + .dining__tabs .dining__nav-tabs {

                .dining__ctrl-tab-btn {
                    padding: 1em 2em .9em;
                    border: 1px solid $dining__color;
                }
                .dining__ctrls-tabs {
                    @include display(flex, row nowrap);
                    max-width: 100%;
                    overflow: auto;
                    scrollbar-width: none; // Firefox
                    -ms-overflow-style: none;  // IE10+

                    &::-webkit-scrollbar { // Webkit
                        width: 0px;
                    }
                }
            }
        }
    }
    &__menu {
        @include list-fix;

        &-tag {
            margin-right: 2em;
            color: $dining__tag-color;

            &s {
                @include list-fix;
                @include display(flex, row wrap);
                margin-top: rem($comp-pad);
            }
        }

        &-item {
            @include display(flex, row nowrap, space-between);

            @media all and (min-width: $bp-med) {
                padding: rem($comp-pad) 0;
            }
            border-bottom: 1px solid rgba($dining__color, .25);

            &:last-child {
                border: 0;
            }
            &-copy {
                margin-right: rem($comp-pad);
            }
            &-title {
                @include font(rem(24px), calc(26/24), $font-h3, 700);
            }
            &-price {
                @include font(rem(16px), calc(26/24), $font-paragraph, 700);
            }
            .dining__menu-tag {
                font-family: $font-h4;
            }
        }
    }
    &__btn {
        @include display(inline-flex, row nowrap, center);
        position: relative;
        width: 100%;
        margin: 1em 0;

        @media all and (min-width: $bp-med) {
            width: 35vw;
            margin: 0 2em;
        }
        @media all and (min-width: $bp-lrg) {
            width: 25vw;
        }
        max-width: rem(475px);
        padding: 1.1em 1.75em 1em;
        appearance: none;
        border-radius: 30px;
        white-space: nowrap;
        @include font(rem(14px), 1, $font-alert);
        border: 1px solid $dining__color;
        @include color(transparent, $dining__color, null, $dining__color, $dining__color, $dining__color);
        transition: background-color .35s ease-out, color .35s ease-out;
    
        &.-active {
            @include color($dining__color, $white, null, $white, $white, $white);
        }
    
        @include hover {
            text-decoration: none;
            cursor: pointer;
            @include color($dining__color, $white, null, $white, $white, $white);
        }

        &:last-child {
            margin: 0;
        }

        &s {
            @include display(flex, column nowrap, null, center);
            width: 100%;
            margin-top: rem(calc($comp-pad * 2));

            @media all and (min-width: $bp-med) {
                @include display(flex, row wrap, center);
            }
        }
    }
}