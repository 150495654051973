&-lifts {
    padding: 0 0 rem($comp-pad);

    @media all and (min-width: $bp-med) {
        padding: 0 0 rem($comp-pad * 3);
    }

    &__wrap {
        @include gutter1;
    }

    &__graph {
        margin-bottom: rem(calc($comp-pad * 3));
    }
    
    &__graph-values {
        @include display(flex, row nowrap, null, center);
        @include position;
        height: $mc-parking__graph-height;
        padding: 0 rem(calc($comp-pad * 1.5));

		.isSB & {
			mix-blend-mode: exclusion;
		}

        &-p {
            @include font(rem(32px), null, $font-h2);
            color: $white;
        }
    }

    &__flood {

        > .mc-lifts__wrap {
        
            > * {
                @include display(inline-block);
                vertical-align: middle;

                &:first-child {
                    margin-right: .5em;
                }
            }
        }
    }

    &__table {
        margin: 0 auto;

        @media all and (max-width: $bp-med) {
            margin-top: rem(calc($comp-pad * -1));

            thead {
                @include display(none);
            }

            tr {
                @include display(flex, row wrap, null, center);
                @include position;
            }

            td {
                flex: 0 1 auto;

                &:first-child {
                    flex: 1 0 auto;
                    padding: rem($comp-pad) 0 0;
                    font-family: $font-h4;
                }

                &:last-child {
                    flex: 0 0 100%;
                    padding: 0 0 rem($comp-pad);
                }

                &.mc__table-cell-svg {
                    transform: translateY(calc(50% + 10px));
                    padding: 0 0 0 rem(calc($comp-pad / 2));
                }
            }
        }
    }

    .mc__heading {
        margin-bottom: 0;
    }

    h2 {
        color: $mc-lifts__h2-color;
    }

    .mc__tab {
        margin-top: rem(calc($comp-pad * -8));
    }

    .mc__flood {
        padding-top: rem($comp-pad * 3);
        padding-bottom: rem($comp-pad * 10);
    }

    @media all and (max-width: $bp-med) {

        + .mc-trails,
        + .mc-parking {
            padding-top: 0;
        }
    }
}