/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/
$global-desktop-break: $bp-lrg;
// MODIFIER : 1.319

/**
 * Heading 1
 */
 h1,
 h2,
 h3,
 h4,
 h5,
 h6{
    margin: 1rem 0;
    font-style: normal;
    font-weight: normal;
    
 }
 p, ul{
     + h1,
     + h2,
     + h3,
     + h4,
     + h5{
         margin-top: 2rem;
     }
 }


// SPELLING OUT HEADINGS PER FIGMA COMP
.h1,.h2,.h3,.h4,.h5,.h6{
    margin: 1rem 0;
    font-style: normal;
    font-weight: normal;

}
.h1,.h2,.h3,.h4,.h5{
    strong, b{
        font-style: normal;
        font-weight: normal;
    }
}

.h1,.h2{
    font-size: 45px;
    line-height: 1.2;
    font-family: $font-h1;
    font-style: normal;

    @media all and (min-width: $global-desktop-break) {
        font-size: 64px;
    }
}
.h3{
    font-size: 26px;
    line-height: 1.142;
    font-family: $font-h2;
    @media all and (min-width: $global-desktop-break) {
        font-size: 32px;
    }
}
.h4{
    font-size: 20px;
    line-height: 1.167;
    font-family: $font-h4;
}
.h5{
    font-size: 16px;
    line-height: 1.2;
    font-family: $font-h4;
}
.h6{
    font-family: $font-h5;
    font-size: 14px;

}
// .h6{
//     font-size: 14px;
//     font-family: $font-alert;
//     @media all and (min-width: $global-desktop-break) {
//         font-size: 14px;

//     }
// }
blockquote{
    @extend .h3;
    
    text-align: center;
    color: $block-quote-color;
    position: relative;

}
ul, ol{
    margin: 25px 0;
    padding-left: 20px;
}
ul ul{
    list-style-type: circle;
}
ol ol{
    list-style-type: lower-alpha;
}
// FLEX STYLE HEADING EXAMPLE 
// MIGHT NOT USE
// h1  {
//     font-size: 2.1875rem;
//     line-height: 1.143;
//     @media all and (min-width: $bp-small-2) {
//        @include fluid-font-size(35, 100, 90);
//    }
// }

 h1  {
    @extend .h1;
 }
 

 h2 {
    @extend .h2;
 }

 h3  {
    @extend .h3;
 }

 h4  {
    @extend .h4;
 }

 h5 {
    @extend .h5;
 }

h6{
    @extend .h6;
}